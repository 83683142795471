import {IonAlert, IonButton, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonPage, IonRange, IonRow} from '@ionic/react';
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import ThreeComponent from "../../components/Three/Three";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import style from "./Evaluation.module.scss";


const EvaluationComponent: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const [red, setRed] = useState(user.typeRed ? user.typeRed : 4);
    const [green, setGreen] = useState(user.typeGreen ? user.typeGreen : 4);
    const [blue, setBlue] = useState(user.typeBlue ? user.typeBlue : 4);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const language = useSelector<AppState, string>(state => state.system.language);


    const calculateValues = (value: number, color: string) => {
        switch (color) {
            case "red":
                setRed(value);
                break;
            case "green":
                setGreen(value);
                break;
            case "blue":
                setBlue(value);
                break;
        }
    };


    const updateUser = async () => {
        try {
            user.typeRed = red;
            user.typeGreen = green;
            user.typeBlue = blue;
            user.changedOwnType = true;
            const response = await userApi.updateUser(language, user.id as number, user);
            dispatch(setUserAction(response.data));
            dispatch(setErrorAction({
                response: {
                    data: {
                        name: t("EVALUATION.ChangeSuccessHeader"),
                        message: t("EVALUATION.ChangeSuccessMessage"),
                    },
                    code: 0
                }
            }) as any);
            history.goBack();
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    return (
        <IonPage>
            <IonAlert
                isOpen={showAlert}
                backdropDismiss={false}
                header={t("EVALUATION.WarningHeader")}
                message={t("EVALUATION.WarningMessage")}
                buttons={[
                    {
                        text: t("GENERAL.Cancel"),
                        role: 'cancel',
                        handler: () => {
                            setShowAlert(false);
                        }
                    },
                    {
                        text: t("GENERAL.Ok"),
                        handler: () => {
                            setShowAlert(false);
                            updateUser();
                        }
                    }
                ]}
            />
            <HeaderComponent {...{showButton: true, color: "secondary"}}/>
            <IonContent className={style.content}>
                <IonGrid className={style.grid}>
                    <IonRow>
                        <IonCol className={style.diamond_col}>
                            <ThreeComponent id={"evaluation"} red={red} green={green} blue={blue} bottomRed={user.subTypeRed} bottomGreen={user.subTypeGreen} bottomBlue={user.subTypeBlue}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Red")} <span className={style.red}>{red}</span></IonLabel>
                                <IonRange
                                    color="diamondRed"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    value={red}
                                    min={3}
                                    max={(48 - green - blue) > 3 ? (48 - green - blue) > 35 ? 35 : (48 - green - blue) : 3}
                                    step={1}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "red");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Green")} <span className={style.green}>{green}</span></IonLabel>
                                <IonRange
                                    color="diamondGreen"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    min={3}
                                    max={(48 - red - blue) > 3 ? (48 - red - blue) > 35 ? 35 : (48 - red - blue) : 3}
                                    step={1}
                                    value={green}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "green");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.range_col}>
                            <IonItem className={style.range_item} lines={"none"}>
                                <IonLabel className={style.label} position={"stacked"}>{t("EVALUATION.Blue")} <span className={style.blue}>{blue}</span></IonLabel>
                                <IonRange
                                    color="diamondBlue"
                                    className={style.range}
                                    mode={"ios"}
                                    pin
                                    min={3}
                                    max={(48 - red - green) > 3 ? (48 - red - green) > 35 ? 35 : (48 - red - green) : 3}
                                    step={1}
                                    value={blue}
                                    onIonChange={event => {
                                        calculateValues(event.detail.value as number, "blue");
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default EvaluationComponent;
