import {AuthActionTypes, AuthState, CLEAR_USER, SET_AUTH_STATE, SET_USER} from "./types";

const INITIAL_STATE: AuthState = {
    isAuthenticated: false,
    token: '',
    user: {
        id: 0,
        username: '',
        email: '',
        password: '',
        name: '',
        surname: '',
        company: '',
        language: '',
        changedOwnType: false,
        changedBigFive: false,
        typeRed: 0,
        typeGreen: 0,
        typeBlue: 0,
        subTypeRed: 0,
        subTypeGreen: 0,
        subTypeBlue: 0,
        personalityType: 0,
        canEvaluate: 0,
        hasEvaluated: 0,
        token: '',
        personalityHtml: '',
        personalityName: '',
        big5Values: [],
        showSurvey: false,
        showResult: false
    }
};

export const authReducer = (state: AuthState = INITIAL_STATE, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case SET_AUTH_STATE:
            return {...state, isAuthenticated: action.payload.isAuthenticated, token: action.payload.token};
        case SET_USER:
            return {...state, user: action.payload};
        case CLEAR_USER:
            return INITIAL_STATE;
        default:
            return state;
    }
};
