import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import {config} from "../index";
import {authReducer} from "./auth/reducers";
import {AuthState} from "./auth/types";
import {dataReducer} from "./data/reducers";
import {DataState} from "./data/types";
import {errorReducer} from "./error/reducers";
import {ErrorState} from "./error/types";
import {privacyReducer} from "./privacy/reducers";
import {PrivacyState} from "./privacy/types";
import {systemReducer} from "./system/reducers";
import {SystemState} from "./system/types";
import { Preferences } from '@capacitor/preferences';

export interface AppState {
    system: SystemState;
    auth: AuthState;
    error: ErrorState;
    privacy: PrivacyState;
    data: DataState;
}

const rootReducer = combineReducers<AppState>({
    system: systemReducer,
    auth: authReducer,
    error: errorReducer,
    privacy: privacyReducer,
    data: dataReducer
});

export default async function configureStore() {
    const middleWare = [thunk];
    const middlewareEnhancer = applyMiddleware(...middleWare);
    const persistendState = await loadState();
    config.accessToken = persistendState?.auth.token;


    return createStore(
        rootReducer,
        persistendState,
        composeWithDevTools(middlewareEnhancer)
    );
}


export const loadState = async (): Promise<AppState | undefined> => {
    try {
        const serializedState = await Preferences.get({key: 'appstate'});
        if (serializedState.value == null) {
            return undefined;
        }
        return JSON.parse(serializedState.value as string);
    } catch (err) {
        return undefined;
    }
};


export const saveState = (state: AppState) => {
    try {
        const serializedState = JSON.stringify(state);
        Preferences.set({key: 'appstate', value: serializedState});
    } catch {
        console.error("Could not save state");
    }
};
