import {Directory, Filesystem} from "@capacitor/filesystem";
import {Network} from "@capacitor/network";
import { Preferences } from '@capacitor/preferences';
import {IonButton, IonCol, IonContent, IonGrid, IonImg, IonItem, IonLabel, IonList, IonSlide, IonSlides, IonPage, IonRow, useIonViewDidEnter} from '@ionic/react';
import Axios from "axios";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {Module} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setLastModuleAction, setLastTrainingAction, setSessionKeysAction, setStoredSessionKeysAction} from "../../store/data/actions";
import {SessionKeyItem} from "../../store/data/types";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Modules.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import '@ionic/react/css/ionic-swiper.css';

const ModulesComponent: React.FC<any> = ({forPerson}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {personId} = useParams<{ personId: string }>();
    const storedSessionKeys = useSelector<AppState, Array<SessionKeyItem>>(state => state.data.storedSessionKeys);
    const language = useSelector<AppState, string>(state => state.system.language);
    const [showTrainings, setShowTrainings] = useState<any>([]);
    const [modules, setModules] = useState<Array<Module>>([]);
    const [videoUrl, setVideoUrl] = useState("");
    const videoRef = useRef();
    const previousUrl = useRef(videoUrl);
    const buff = require('buffer');

    useEffect(() => {
        // download images and store it as file
        const setImagesAsFile = async (modules: Array<Module>) => {
            for (let module of modules) {
                const result = await Axios.get(module.iconUrl as string, {responseType: "arraybuffer"});
                const image = buff.Buffer.from(result.data, "base64").toString("base64");
                const filePath = "Module-" + module.id + "-icon.png";
                await Filesystem.writeFile({
                    data: image,
                    path: filePath,
                    directory: Directory.Data
                });
                const file = await Filesystem.getUri({
                    directory: Directory.Data,
                    path: filePath
                });

                module.iconFile = file.uri;
            }
            return modules;
        };


        const getModules = async () => {
            dispatch(setShowLoadingAction(true));
            try {
                let response;
                if (forPerson as boolean) {
                    response = await dataApi.getModuleForPerson(personId as unknown as number, language);
                } else {
                    response = await dataApi.getModules(language);
                }

                // store all session ids in state for version checking
                const sessionKeys = [];
                for (let module of response.data) {
                    if (module.training) {
                        for (let training of module.training) {
                            if (training.sessions) {
                                for (let session of training.sessions) {
                                    sessionKeys.push({key: "Session-" + session.id, version: session.version as number});
                                }
                            }
                        }
                    }
                }
                dispatch(setSessionKeysAction(sessionKeys));

                // check for old sessions in storage
                for (let i = 0; i < storedSessionKeys.length; i++) {
                    let found = false;
                    for (let key of sessionKeys) {
                        if (storedSessionKeys[i].key === key.key) {
                            found = true;
                        }
                    }
                    if (!found) {
                        Preferences.remove({key: "Session-" + storedSessionKeys[i].key});
                        storedSessionKeys.splice(i, 1);
                        i--;
                    }
                }
                dispatch(setStoredSessionKeysAction(storedSessionKeys));

                const modulesWithImages = await setImagesAsFile(response.data);
                for (const module of modulesWithImages) {
                    module.iconFile = await getImageFromFile(module.iconFile as string);
                }
                setModules(modulesWithImages);
                setVideoUrl(modulesWithImages[0].videoUrl as string);
                await Preferences.set({key: "Modules", value: JSON.stringify(modulesWithImages)});
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                console.log(e);
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
            }
        };


        const getStoredModules = async () => {
            dispatch(setShowLoadingAction(true));
            const loadedModules = await Preferences.get({key: "Modules"});
            const storedModules = JSON.parse(loadedModules.value as string);
            for (const module of storedModules) {
                module.videoUrl = "";
                module.iconFile = await getImageFromFile(module.iconFile);

            }
            setModules(storedModules);
            dispatch(setShowLoadingAction(false));
        };


        Network.getStatus().then((status: any) => {
            if (status.connected) {
                getModules();
            } else {
                console.log("Load from Storage");
                getStoredModules();
            }
        })
    }, [language]);

    const getImageFromFile = async (path: string) => {
        try {
            const file = await Filesystem.readFile({
                path: path
            });
            const loadedImage = "data:image/png;base64," + file.data;
            return (loadedImage);
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            console.error(e);
            return "";
        }
    };

    useEffect(() => {
        if (previousUrl.current === videoUrl) {
            return;
        }

        if (videoRef.current) {
            // @ts-ignore
            videoRef.current.load();
        }

        previousUrl.current = videoUrl;
    }, [videoUrl]);


    const InnerList = (module: Module) => {
        return (
            <IonList className={style.training_list}>
                {module.training?.map((training, i) => {
                    return (
                        <IonItem key={i} button className={style.training} onClick={event => {
                            dispatch(setLastTrainingAction(training.id));
                            dispatch(setLastModuleAction(module));
                            history.push('/modules/training/' + training.id);
                        }}>
                            <IonLabel>
                                <p className={style.trainingTitle}>
                                    <span className={style.title} dangerouslySetInnerHTML={{__html: training.title as string}}></span>
                                    <span dangerouslySetInnerHTML={{__html: training.description as string}}></span>
                                </p>
                            </IonLabel>
                        </IonItem>
                    );
                })}
            </IonList>
        )
    };


    return (
        <IonPage>
            <HeaderComponent {...{showButton: forPerson, color: "module", defaultHref: forPerson ? '/modules/sales' : ''}}/>
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {!forPerson && <h5 className={style.title}>{t('MODULES.Title')}</h5>}
                            {forPerson && <h5 className={style.title}>{t('MODULES.Title2')}</h5>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Swiper className={style.slider}> 

                                {modules.map((module, i) => {
                                    if (!module.isLocked) {
                                        // active modules

                                        return (
                                            <SwiperSlide className={style.slide} key={i}>
                                                {module.videoUrl &&
                                                // @ts-ignore
                                                <video controls preload="metadata" autoPlay={false} webkit-playsinline="webkit-playsinline" className={style.video_player} ref={videoRef}>
                                                    <source src={videoUrl + "#t=0.1"} type="video/mp4"/>
                                                </video>}

                                                {(module.videoUrl == null || module.videoUrl === "") && module.iconUrl && <IonImg className={style.image} src={module.iconUrl}/>}

                                                <div className={style.innerContent}>
                                                    <h5 dangerouslySetInnerHTML={{__html: module.title as string}}/>
                                                    <p>{module.description}</p>
                                                    {(module.contentType !== "sales" || forPerson)
                                                    && (module.training && module.training.length > 0)
                                                    && <IonButton expand={"block"} className={style.module_button} onClick={() => {
                                                        let newArray = new Array(modules.length).fill(false);
                                                        if (!showTrainings[i]) {
                                                            newArray[i] = true;
                                                        }
                                                        setShowTrainings(newArray);
                                                    }}>

                                                        {!showTrainings[i] && t('MODULES.ShowTrainings')}
                                                        {showTrainings[i] && t('MODULES.HideTrainings')}
                                                    </IonButton>}
                                                    {module.contentType === "sales" && !forPerson && <IonButton expand={"block"} className={style.module_button} routerLink={"/modules/sales"}>
                                                        {t("MODULES.ShowSales")}
                                                    </IonButton>}
                                                    {showTrainings[i] && InnerList(module)}
                                                </div>
                                            </SwiperSlide>
                                        )
                                    } else {
                                        // locked modules
                                        return (
                                            <SwiperSlide className={classNames(style.slide, style.slide_locked)} key={i}>
                                                {module.iconUrl && <IonImg className={style.image} src={module.iconFile}/>}
                                                <div className={style.innerContent}>
                                                    {/*<p className={style.module_count}>{t('MODULES.ModuleCount')}{i + 1}</p>*/}
                                                    <h5 dangerouslySetInnerHTML={{__html: module.title as string}}/>
                                                    <p>{module.description}</p>
                                                    <IonButton expand={"block"} color={"disabled"} className={style.module_button} target={"_blank"} href={"https://www.mindcodex.de/startseite"}>
                                                        {t("MODULES.BookingWebsite")}
                                                    </IonButton>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                })}
                            </Swiper>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ModulesComponent;
