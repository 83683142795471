import {IonAlert, IonCol, IonContent, IonGrid, IonPage, IonRow, IonButton} from "@ionic/react";
import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {Quiz, Session, User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi, quizApi} from "../../index";
import {AppState} from "../../store";
import { SET_AUTH_STATE } from "../../store/auth/types";
import {deleteStoredSessionAction} from "../../store/data/actions";
import {DataState} from "../../store/data/types";
import {setErrorAction} from "../../store/error/actions";
import style from "./Quiz.module.scss";
import { getPlatforms } from '@ionic/react';

import { Filesystem } from '@capacitor/filesystem';
import { Preferences } from "@capacitor/preferences";

const QuizComponent: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const {sessionId} = useParams<{ sessionId: string }>();
    const [session, setSession] = useState<Session>({});
    const [image, setImage] = useState<string>("");
    const [showAlert, setShowAlert] = useState(false);
    const storedSessionKeys = useSelector<AppState, DataState["sessionKeys"]>(state => state.data.storedSessionKeys);
    const [quiz, setQuiz] = useState<Quiz>({});
    const user = useSelector<AppState, User>(state => state.auth.user);
    const [questionNo, setQuestionNo] = useState<number>(0);
    const [lastClicked, setLastClicked] = useState<number | null>(null);
    const [tryCount, setTryCount] = useState<number>(0);
    const [results, setResults] = useState<Array<any>>([])
    const [showResults, setShowResults] = useState<boolean>(false);
    const [weiterDisabled, setWeiterDisabled] = useState<boolean>(true);
    const [gesamtResults, setGesamtResults] = useState<number>(0);
    const language = useSelector<AppState, string>(state => state.system.language);
    const [endText, setEndText] = useState<string>("");

    const { id, sid, speed } = useParams<{ id: string, sid: string, speed:string }>();
    const videoRef = useRef(null);

    const platforms = getPlatforms();

    const isTablet = platforms.includes("ipad") || platforms.includes("tablet");

    const videoklass = id != "5" ? style.video_player : style.video_player_alt;
    const rowklass = id != "5" && !isTablet ? style.videoRow: style.videoRowAlt;

    let loadingLock = false;

    //setup colors
    let initialColors:any = [];
    for (let i=0; i<8; i++)
        initialColors.push("light");

    //const [indexPressed, setIndexPressed] = useState<any>(Infinity);
    //const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [colors, setColors] = useState(initialColors);

    //let answers:string[] = []; // ["Wut", "Angst", "Traurigkeit", "Freude", "Überraschung", "Ekel", "Verachtung"];
    //let rightIndex = 1; //Math.round(Math.random() * 7);

    function shuffle(array: any) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    useEffect(() => {
        const getQuiz = async () => {
            try {
                let quizId = id == "5" ? 2 : 1;
                let _q = await quizApi.getQuiz(quizId, language);
                console.log(_q);
                _q.data.questions = shuffle(_q.data.questions);

                setQuiz(_q.data);

                let s = await dataApi.getSession(parseInt(sid,10), language);
                setEndText(s.data.endText || '');
            } catch (e) {
                dispatch(setErrorAction(e) as any);
            }
        };

        const getImage = async (loadedSession: Session) => {
            Filesystem.readFile({
                path: loadedSession.imageFile as string
            }).then((res: any) => {
                const loadedImage = "data:image/png;base64," + res.data;
                setImage(loadedImage);
            }).catch((err: any) => {
                console.error(err);
                dispatch(deleteStoredSessionAction(sessionId, storedSessionKeys))
                setShowAlert(true);
            });
        };

        const setSessionRead = async (loadedSession: Session) => {
            try {
                await dataApi.setSessionAsRead(loadedSession.id as number);
            } catch (e) {
                dispatch(setErrorAction(e) as any);
            }
        };

        Preferences.get({key: "Session-" + sessionId}).then(async (data:any) => {
            if (data.value) {
                const loadedSession = JSON.parse(data.value);
                setSession(loadedSession);
                await getImage(loadedSession);
                await setSessionRead(loadedSession);
            } else {
                setTimeout(() => {
                    setShowAlert(true);
                }, 1500);
            }
        });

        getQuiz();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    const answerClicked = (idx: any) => {
       let mutableColors:any = [...colors];

        let rightIndex = quiz && quiz.questions && quiz.questions[questionNo].correctAnswerIndex;
        let question = quiz && quiz.questions && quiz.questions[questionNo].question;
        let isCorrect = (idx === rightIndex);

        let newResults = [... results];

        if (!quiz.questions)
            return;
           
        let percentPerQuestion = 100 / (quiz.count as number);

        let questionResult = newResults.find(q => q.answer === question);
        if (questionResult) {
            questionResult.score += (isCorrect && !tryCount) ? percentPerQuestion : 0;
        } else {
            newResults.push({
                answer: quiz.questions[questionNo].question,
                score: (isCorrect && !tryCount) ? percentPerQuestion : 0
            });
        }

        setResults(newResults);    

        if (isCorrect) {
            setTryCount(0);
            setWeiterDisabled(false);
        } else {
            setTryCount(tryCount + 1);
        }

        mutableColors[idx] = isCorrect ? "success" : "primary";
        setColors(mutableColors);          
        setLastClicked(idx);  
    }

    const checkAndSave = () => {
        if (!quiz.questions)
        return;

        if (quiz.questions.length-1 === questionNo) {
            setShowResults(true);

            let gesamt = 0;
            results.forEach(r => gesamt += r.score);
            gesamt /= results.length;

            setGesamtResults(gesamt);
            
            quizApi.postQuizResult(quiz.id as number, user.id as number, {
                userId: user.id as number,
                participationScore: gesamt / 100,
                score: results
            });
        }
    }

    const setPlaybackRate = () => {
        if (!videoRef || !videoRef.current || videoRef.current == null) {
            console.log("videoRef not ready yet ... ");
            return;
        }

        console.log("speed", speed);

        if (videoRef && videoRef.current && videoRef.current !== null) {
            let _speed = (speed == 'fast') ? quiz.speed_fast : quiz.speed_normal;
            (videoRef.current as unknown as HTMLVideoElement).playbackRate = _speed as number;
        }

        console.log("setPlaybackRate", (videoRef.current as unknown as HTMLVideoElement).playbackRate);
/*
        setTimeout(_=>{
            if (videoRef && videoRef.current && videoRef.current !== null)
                (videoRef.current as unknown as HTMLVideoElement).play();

                console.log("setTimeout setplaybackrate");
        },1000);
*/
      };

      useEffect(() => {
        setTimeout( _=>{
            var video = document.getElementById("quizVideoPlayer")
    
            console.log("enter");
    
            if (loadingLock) {
                console.log("! loading lock");
                return;
            }
    
            if (!quiz || !quiz.questions || !quiz.questions[questionNo]) {
                console.log("not ready");
                return;
            }
    
            loadingLock = true;
                
            var url = quiz.questions[questionNo].video || '';

            if (!url) {
                console.log("! url empty");
                return;
            }
            
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "arraybuffer";
            
            xhr.onload = function(oEvent) {
            
                var blob = new Blob([(oEvent.target as XMLHttpRequest).response], {type: "video/mp4"});
            
            //    (videoRef as unknown as HTMLVideoElement).src = URL.createObjectURL(blob);
            
            if (videoRef && videoRef.current)
                (videoRef.current as unknown as HTMLVideoElement).src = URL.createObjectURL(blob);
    
                //video.play()  if you want it to play on load
            };
            
            xhr.onprogress = function(oEvent) {
            
                if (oEvent.lengthComputable) {
                    var percentComplete = oEvent.loaded/oEvent.total;
                    console.log("video " +url+ " % " + percentComplete);
                    if (percentComplete == 1) {
    
                        let _speed = (speed == 'fast') ? quiz.speed_fast : quiz.speed_normal;
                
                        console.log("! play");
                        setTimeout(_=>{
                            if (videoRef && videoRef.current && videoRef.current !== null) {
                    
                                (videoRef.current as unknown as HTMLVideoElement).playbackRate = _speed as number;
            
                                (videoRef.current as unknown as HTMLVideoElement).play();
                    
                                    console.log("setTimeout replay");
                            }
                        },0);
                    }
                }
            }
            
            xhr.send();
        },0);
      });

      const replay = () => {

        setTryCount(tryCount + 1);

        setTimeout(_=>{
            if (videoRef && videoRef.current && videoRef.current !== null)
                (videoRef.current as unknown as HTMLVideoElement).play();

                console.log("setTimeout replay");
        },1000);
      }

    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "fixedHeaderBlue"}}/>
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h3 className={style.title}>{quiz.title}</h3>
                        </IonCol>
                    </IonRow>

                    {showResults && <IonRow>
                        <IonCol>
                            <h4 className={style.colorWhite}>{t("QUIZ.Results")}</h4>
                        </IonCol>
                    </IonRow>}

                    {showResults && results && results.map((result:any,index:number)=>{
                    return <IonRow>
                                <IonCol><span className={style.colorWhite}>{result.answer}</span></IonCol>   
                                <IonCol><b className={style.colorWhite}>{result.score}%</b></IonCol>    
                            </IonRow>
                    })}

                    {showResults && gesamtResults && 
                        <IonRow>
                            <IonCol><span className={style.colorWhite}>{t("QUIZ.Total")}</span></IonCol>    
                            <IonCol><b className={style.colorWhite}>{gesamtResults.toFixed(1)}%</b></IonCol>    
                        </IonRow>
                    }

                    <IonRow hidden={!showResults}>
                        <IonCol class="ion-text-center">
                        <IonButton key="again" color="secondary" onClick={event => {
                                window.location.reload();
                            }}>{t("QUIZ.Retry")}</IonButton>
                        </IonCol>
                    </IonRow>
                    {endText && <IonRow hidden={!showResults}>
                        <IonCol><p className={style.colorWhite} dangerouslySetInnerHTML={{__html: endText}}/></IonCol>
                    </IonRow>}

                    <IonRow hidden={showResults} className={rowklass}>
                        <IonCol>
                            {quiz && quiz.questions && quiz.questions[questionNo] && quiz.questions[questionNo].correctAnswerIndex != lastClicked && 
                            
                            <video ref={videoRef} preload="auto" playsInline={true} loop={false} id="quizVideoPlayer"
                            poster="assets/transparent.png"
                            webkit-playsinline="webkit-playsinline" className={videoklass}>
                                
                            </video> }
                            {quiz && quiz.questions && quiz.questions[questionNo] && quiz.questions[questionNo].correctAnswerIndex == lastClicked && <img className={id != "5" ? style.videoImage : ""} src={quiz.questions[questionNo].image} /> }
                        </IonCol>
                     </IonRow>
                    <IonRow hidden={showResults}>
                        <IonCol className={style.center}>
                        {quiz && quiz.answers && quiz.answers.map((answer:string,index:number)=>{
                            return <IonButton key={index} color={colors[index]} className={style.quizAnswer} fill="outline" disabled={!weiterDisabled} onClick={event => {
                                answerClicked(index);
                            }}>{answer}</IonButton>
                        })}
                        </IonCol>
                    </IonRow>
                    <IonRow hidden={showResults}>
                        <IonCol class="ion-text-center">
                            <IonButton key="reset" color="secondary" expand="block"
                                onClick={event => {
                                    replay();
                                }}>{t("QUIZ.Replay")}</IonButton>
                        </IonCol>
                        <IonCol class="ion-text-center">
                            <IonButton key="reset" color="secondary" disabled={weiterDisabled} expand="block"
                                onClick={event => {
                                    setColors(initialColors);
                                    setQuestionNo(questionNo+1);
                                    setWeiterDisabled(true);
                                    setLastClicked(null);
                                    checkAndSave();
                                }}>{t("QUIZ.Next")}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );

};

export default QuizComponent;
