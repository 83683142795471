import { Preferences } from '@capacitor/preferences';
import {IonButton, IonCol, IonGrid, IonHeader, IonModal, IonRow, IonTitle, IonToolbar} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Privacy} from "../../Api";
import {miscApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {clearPrivacyAction, setPrivacyAcceptedAction, setPrivacyVersionAction, setShowPrivacyAction} from "../../store/privacy/actions";
import style from './Privacy.module.scss';

const PrivacyModal: React.FC = () => {
    const dispatch = useDispatch();
    const showPrivacy = useSelector<AppState, boolean>(state => state.privacy.showPrivacy);
    const [privacy, setPrivacy] = useState<Privacy>({html: "", version: 0});
    const {t} = useTranslation();
    const language = useSelector<AppState, string>(state => state.system.language);
    const privacyVersion = useSelector<AppState, number>(state => state.privacy.privacyVersion);
    const privacyAccepted = useSelector<AppState, boolean>(state => state.privacy.privacyAccepted);

    useEffect(() => {
        const getPrivacy = async () => {
            try {
                const response = await miscApi.getPrivacy(language);
                if (response.data != null) {
                    setPrivacy(response.data);
                    Preferences.set({key: "privacy", value: JSON.stringify(response.data)});
                    if (response.data.version && response.data.version > privacyVersion) {
                        dispatch(setPrivacyAcceptedAction(false));
                    }
                    dispatch(setShowPrivacyAction(!privacyAccepted));
                }
            } catch (e) {
                console.error((e as any).error);
                Preferences.get({key: "privacy"}).then(data => {
                    if (data.value) {
                        const privacy = JSON.parse(data.value);
                        setPrivacy(privacy);
                        dispatch(setShowPrivacyAction(!privacyAccepted));
                   }
                })
            }
        };
        getPrivacy();
    }, [language]);

    return (
        <IonModal
            isOpen={showPrivacy}
            backdropDismiss={false}
            className={style.modal}
        >
            <IonHeader className={style.header} mode={"md"}>
                <IonToolbar className={style.header_toolbar}>
                    <IonTitle>{t('TABS.Privacy')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonGrid className={style.grid}>
                <IonRow>
                    <IonCol>
                        <p className={style.innerHtml} dangerouslySetInnerHTML={{__html: privacy.html as string}}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="4">
                        <IonButton color="secondary" expand="block" onClick={event => {
                            dispatch(clearPrivacyAction());
                            dispatch(clearUserAction() as any);
                            dispatch(setShowPrivacyAction(false));
                        }}>{t('GENERAL.Decline')}</IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton expand="block" onClick={event => {
                            dispatch(setPrivacyAcceptedAction(true));
                            dispatch(setPrivacyVersionAction(privacy.version as number));
                            dispatch(setShowPrivacyAction(false));
                        }}>{t('GENERAL.Accept')}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>
    );
};

export default PrivacyModal;
