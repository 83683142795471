import {IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar} from "@ionic/react";
import React from 'react';
import style from './Header.module.scss';


export const HeaderComponent: React.FC<any> = (props) => {
    return (
        <IonHeader mode={"ios"} className={style.header}>
            <IonToolbar color={props.color} className={style.header_toolbar}>
                {props.showButton && <IonButtons>
                    <IonBackButton className={style.back_button} text={""} defaultHref={props.defaultHref}/>
                </IonButtons>}
                <IonTitle>
                    <img className={style.logo} src={"/assets/logo/logo.svg"} alt={""}/>
                </IonTitle>
            </IonToolbar>
        </IonHeader>
    );
};
