import React, {useEffect, useState} from "react";
import * as THREE from "three";
import {ShaderMaterial, Uniform} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import style from './style.module.scss';


const ThreeComponent: React.FC<any> = (props) => {

    const [modelStore, setModelStore] = useState();
    const [bottomStore, setBottomStore] = useState();
    let bottom: any;
    let model: any;
    let scene: any, camera: any, renderer: any, refractionMaterial: any, controls: any;

    let vp = {
        width: window.innerWidth,
        height: window.innerHeight / 2,
        dpr: Math.min(devicePixelRatio, 2 || 1)
    };

    let velocity = 0.005;


    const setup = async () => {
        console.log("[DIAMOND] setup [enter]");
        createScene();
        await createModel();
        await createBottom();
        render();
        setModelStore(model);
    }

    const createScene = () => {
        scene = new THREE.Scene();
        camera = new THREE.PerspectiveCamera(
            50,
            vp.width / vp.height,
            0.1,
            1000
        );


        camera.position.set(40, 20, 40);

        const light1 = new THREE.DirectionalLight(0xcecece, 1);
        light1.position.set(10, 12, 10);
        scene.add(light1);

        const light2 = new THREE.DirectionalLight(0xcecece, 0.6);
        light2.position.set(-1, -10, -1);
        scene.add(light2);

        const light3 = new THREE.AmbientLight(0x222222);
        scene.add(light3);


        renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});

        renderer.setSize(vp.width, vp.height);
        renderer.setPixelRatio(vp.dpr);
        renderer.autoClear = false;


        controls = new OrbitControls(camera, renderer.domElement);
        controls.enablePan = false;
        controls.maxDistance = 150;
        controls.minDistance = 90;
        controls.rotateSpeed = 0.5;


        // @ts-ignore
        document.querySelector("#" + props.id).innerHTML = "";
        // @ts-ignore
        document.querySelector("#" + props.id).appendChild(renderer.domElement);
    }


    const createModel = async () => {
        return new Promise(resolve => {

            refractionMaterial = new ShaderMaterial({
                vertexShader: 'varying vec4 color; varying vec3 worldNormal; varying vec3 viewDirection; void main() {vec4 worldPosition = modelMatrix * vec4( position, 1.0); worldNormal = normalize( modelViewMatrix * vec4(normal, 0.)).xyz; viewDirection = normalize(worldPosition.xyz - cameraPosition); gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.0);}',
                fragmentShader: 'uniform sampler2D envMap; uniform vec2 resolution; varying vec3 worldNormal; varying vec3 viewDirection; float ior = 1.5; vec3 reflectionColor = vec3(1.0); float fresnelFunc(vec3 viewDirection, vec3 worldNormal) {return pow( 1.0 + dot( viewDirection, worldNormal), 3.0 );} void main() {vec2 uv = gl_FragCoord.xy / resolution;vec3 normal = worldNormal;vec3 refracted = refract(viewDirection, normal, 1.0/ior);uv += refracted.xy;vec4 tex = texture2D(envMap, uv);float fresnel = fresnelFunc(viewDirection, normal);vec4 color = tex;color.rgb = mix(color.rgb, reflectionColor, fresnel);gl_FragColor = vec4(color.rgb, 1.0);}',
                uniforms: {
                    resolution: new Uniform([vp.width * vp.dpr, vp.height * vp.dpr])
                },
            });


            const loader = new GLTFLoader();
            loader.load("/assets/diamond/diamond5.glb", gltf => {
                model = gltf.scene;
                model.scale.set(30, 30, 30);
                model.position.set(0, 20, 0);
                model.children[0].material = refractionMaterial;

                setColor();
                scene.add(model);
                setModelStore(model);
                resolve(null);
            });
        })
    }

    const createBottom = async () => {
        return new Promise(resolve => {
            const loader = new GLTFLoader();
            loader.load("/assets/diamond/bottom3.glb", gltf => {
                bottom = gltf.scene;
                bottom.scale.set(30, 30, 30);
                bottom.position.set(0, -10, 0);
                bottom.rotation.y = -0.1;
                setBottomColor();
                scene.add(bottom);
                setBottomStore(bottom);
                resolve(null);
            });
        })
    }

    const render = () => {
        window.requestAnimationFrame(render);

        renderer.clear();
        controls.update();

        velocity *= 0.87;
        model.rotation.y += velocity + Math.sign(velocity) * 0.005;


        renderer.render(scene, camera);
    }

    const setColor = () => {
        let m;
        if (model) {
            m = model;
        } else if (modelStore) {
            m = modelStore;
        } else {
            return;
        }
        const red = props.red;
        const blue = props.blue;
        const green = props.green;

        for (let i = 1; i < red + 1; i++) {
            m.children[i].material.color.setHex(0xFF1F33);
        }

        for (let i = red + 1; i < red + blue + 1; i++) {
            m.children[i].material.color.setHex(0x007DFF);
        }
        for (let i = red + blue + 1; i < red + blue + green + 1; i++) {
            m.children[i].material.color.setHex(0xFFDB00);
        }

        for (let i = red + blue + green + 1; i < 49; i++) {
            m.children[i].material.color.setHex(0xFEFEFE);
        }
        m.children[49].material.transparent = true;
        m.children[49].material.opacity = 1;
        m.children[49].material.color.setHex(0xFF7D1A);
    }

    const setBottomColor = () => {
        let m;
        if (bottom) {
            m = bottom;
        } else if (bottomStore) {
            m = bottomStore;
        } else {
            return;
        }
        const red = props.bottomRed;
        const blue = props.bottomBlue;
        const green = props.bottomGreen;


        for (let i = 0; i < 15; i++) {
            m.children[i].material.transparent = true;
            m.children[i].material.opacity = 0.2;
            m.children[i].material.color.setHex(0xFEFEFE);
        }

        for (let i = 4; i > 4 - red; i--) {
            m.children[i].material.transparent = true;
            m.children[i].material.opacity = 1;
            m.children[i].material.color.setHex(0xFF1F33);
        }

        for (let i = 9; i > 9 - blue; i--) {
            m.children[i].material.transparent = true;
            m.children[i].material.opacity = 1;
            m.children[i].material.color.setHex(0x007DFF);
        }
        for (let i = 14; i > 14 - green; i--) {
            m.children[i].material.transparent = true;
            m.children[i].material.opacity = 1;
            m.children[i].material.color.setHex(0xFFDB00);
        }

        m.children[15].material.transparent = true;
        m.children[15].material.opacity = 1;
        m.children[15].material.color.setHex(0xFF7D1A);

    }


    useEffect(() => {
        setup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setColor();
        setBottomColor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <div id={props.id} className={style.scene}/>
    )
}

export default ThreeComponent;
