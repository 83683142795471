import {Dispatch} from "react";
import {useTranslation} from "react-i18next";
import {setShowLoadingAction} from "../system/actions";
import {CLEAR_ERRORS, SET_ERRORS} from "./types";

export const setErrorAction = (error: any) => (dispatch: Dispatch<any>) => {
    console.error(error.response);
    dispatch(setShowLoadingAction(false));


    const payload = {
        message: error.response?.data?.message ? error.response?.data.message : "default",
        title: error.response?.data?.name ? error.response?.data?.name : "default",
        code: error.response?.status
    };

    dispatch({
        type: SET_ERRORS,
        payload: payload
    });
};

export const clearErrorsAction = () => {
    return {
        type: CLEAR_ERRORS,
        payload: {}
    };
};
