import {IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonPage, IonRow, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter} from "@ionic/react";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {HeaderComponent} from "../../components/Header/Header";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {setAuthAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Login.module.scss';

const LoginComponent: React.FC = () => {

    const dispatch = useDispatch();
    let currentState = useSelector<AppState, AppState>(state => state);
    const {t} = useTranslation();
    const user = currentState.auth.user;
    const history = useHistory();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const language = useSelector<AppState, string>(state => state.system.language);

    useIonViewDidEnter(() => {
        console.log("[LOGIN] useIonViewDidEnter (enter)");
    });

    useIonViewDidLeave(() => {
        console.log("[LOGIN] useIonViewDidEnter (leave)"); 
    })

    const login = async () => {
        try {
            console.log("[LOGIN] login (enter)");
            dispatch(setShowLoadingAction(true));
            user.password = password;
            user.username = username;
            const response = await userApi.login(language, user);
            dispatch(setUserAction(response.data));
            dispatch(setAuthAction(response.data));
            history.replace("/home");
            console.log("[LOGIN] redirect to home");
            dispatch(setShowLoadingAction(false));
            console.log("[LOGIN] login (leave)");
        } catch (e) {
            console.log(e);
            dispatch(setErrorAction(e) as any);
        }
    };


    return (
        <IonPage>
            <HeaderComponent {...{color: "headerBlue"}}/>
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol className={style.logo}>
                            <img src={"./assets/logo/logo_no_text.svg"} alt={""}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="12">
                            <p className={style.text_white}>{t("LOGIN.Login")}</p>
                        </IonCol>
                        <IonCol>
                            <IonItem>
                                <IonInput type='text' placeholder={t('GENERAL.Email')} onIonChange={event => {
                                    setUsername(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonInput type='password' placeholder={t('GENERAL.Password')} onIonChange={event => {
                                    setPassword(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.login_buttons}>
                        <IonCol>
                            <IonButton color={"white"} expand={"block"} fill={(password !== '' && username !== '') ? "solid" : "outline"} onClick={async event => {
                                login();
                            }}>{t('LOGIN.Login')}</IonButton>
                        </IonCol>

                        <IonCol>
                            <IonButton color={"white"} expand={"block"} fill={"outline"} routerLink="/registration">{t('LOGIN.Registration')}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.info_buttons}>
                        <IonCol>
                            <IonButton color={"white"} fill={"clear"} href={"https://www.mindcodex.de/registrierung/passwort-zuruecksetzen"}
                                       target="__blank">{t('LOGIN.ResetPassword')}</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton color={"white"} fill={"clear"} routerLink={"/info"} routerDirection={"forward"}>{t('LOGIN.Info')}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};


export default LoginComponent;