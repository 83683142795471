import {App} from "@capacitor/app";
import {Device} from "@capacitor/device"
import {SplashScreen} from "@capacitor/splash-screen";
import {StatusBar, Style} from "@capacitor/status-bar";
import {IonAlert, IonApp, IonIcon, IonLabel, IonLoading, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs,} from '@ionic/react'
import {IonReactRouter as IRR} from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import './theme/variables.css';

import classNames from "classnames";
import i18n from "i18next";
import {clipboardOutline} from "ionicons/icons";
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, Route, useHistory} from 'react-router'
import {User} from "./Api";
import {userApi} from "./index";
import Big5Component from "./pages/Big5/Big5";
import DominancesComponent from "./pages/Dominances/Dominances";
import EvaluationComponent from "./pages/Evaluation/Evaluation";
import HomeComponent from "./pages/Home/Home";
import InfoComponent from "./pages/Info/Info";
import LoginComponent from "./pages/Login/Login";
import ModulesComponent from "./pages/Modules/Modules";
import PrivacyModal from "./pages/Privacy/Privacy";
import ProfileComponent from "./pages/Profile/Profile";
import RegistrationComponent from "./pages/Registration/Registration";
import SalesComponent from './pages/Sales/Sales';
import SessionComponent from "./pages/Session/Session";
import SurveyComponent from "./pages/Survey/Survey";
import TrainingComponent from "./pages/Training/Training";
import {AppState} from './store'
import {clearUserAction, setUserAction} from "./store/auth/actions";
import {clearDataStateAction} from "./store/data/actions";
import {clearErrorsAction, setErrorAction} from "./store/error/actions";
import {ErrorState} from "./store/error/types";
import {setLanguageAction, setShowLoadingAction, setBuildAction, setVersionAction} from "./store/system/actions";
import QuizComponent from "./pages/Quiz/Quiz";

import { setupIonicReact } from '@ionic/react';
 
setupIonicReact();

const Mindcodex: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isAuthenticated = useSelector<AppState, boolean>(state => state.auth.isAuthenticated);
    const language = useSelector<AppState, string>(state => state.system.language);
    const languageSetByUser = useSelector<AppState, boolean>(state => state.system.languageSetByUser);
    const user = useSelector<AppState, User>(state => state.auth.user);
    const error = useSelector<AppState, ErrorState>(state => state.error);
    const showLoading = useSelector<AppState, boolean>(state => state.system.showLoading);
    const {t} = useTranslation();
    const possibleLanguages = ["de", "en"];
    const build = useSelector<AppState, string>(state => state.system.build);

    const IonReactRouter = IRR as any;

    useEffect(() => {
        const initApp = async () => {
            const info = await Device.getInfo();
            if (!info.platform.includes("web")) {
                StatusBar.setStyle({style: Style.Dark});
                SplashScreen.hide();
                dispatch(setShowLoadingAction(false));

                const info = await App.getInfo()
                const resetApp = info.build !== build;

                if (resetApp) {
                    dispatch(clearUserAction() as any);
                    dispatch(setVersionAction(info.version));
                    dispatch(setBuildAction(info.build));
                    history.replace("/login");
                }
            }
        }
        initApp();
    }, []);	

    useEffect(() => {
        const getPrefLang = async () => {
            try {
                const prefLang = await Device.getLanguageCode();
                const prefLangShort = prefLang.value.split("-")[0];
                if (!languageSetByUser) {
                    if (possibleLanguages.includes(prefLangShort)) {
                        dispatch(setLanguageAction(prefLangShort));
                    } else {
                        dispatch(setLanguageAction("en"));
                    }
                } else {
                    dispatch(setLanguageAction(language));
                }
            } catch (e) {
                dispatch(setLanguageAction("en"));
            }
        }
        getPrefLang();
    }, []);

    
    useEffect(() => {
        i18n.changeLanguage(language);
        //dispatch(clearDataStateAction());
    }, [language])
    
    const TabsBar = () => {
        return (
            <IonTabBar slot='bottom'>
                <IonTabButton tab='home' href='/home' className={"tab"}>
                    <div className={classNames("tab_icon", "tab_icon_home")}/>
                    <IonLabel className={"tab_label"}>{t('TABS.Home')}</IonLabel>
                </IonTabButton>
                <IonTabButton tab='modules' href='/modules' className={"tab"}>
                    <div className={classNames("tab_icon", "tab_icon_modules")}/>
                    <IonLabel className={"tab_label"}>{t('TABS.Modules')}</IonLabel>
                </IonTabButton>
                {user.showSurvey && <IonTabButton tab='survey' href='/survey' className={'tab'}>
                    <IonIcon className={"tab_icon"} icon={clipboardOutline}/>
                    <IonLabel className={"tab_label"}>{t('TABS.Survey')}</IonLabel>
                </IonTabButton>}
                {user.showResult && <IonTabButton tab='evaluation' href='/evaluation' className={"tab"}>
                    <div className={classNames("tab_icon", "tab_icon_dominances")}/>
                    <IonLabel className={"tab_label"}>{t('TABS.Dominances')}</IonLabel>
                </IonTabButton>}
                <IonTabButton tab='info' href='/info' className={"tab"}>
                    <div className={classNames("tab_icon", "tab_icon_info")}/>
                    <IonLabel className={"tab_label"}>{t('TABS.Info')}</IonLabel>
                </IonTabButton>
            </IonTabBar>
        )
    };

    const errorAlert = () => {
        return (
            <IonAlert
                isOpen={error.isOpen}
                backdropDismiss={false}
                header={error.title != "default" ? error.title : t("GENERAL.ErrorHeader")}
                message={error.message != "default" ? error.message : t("GENERAL.ErrorMessage")}
                buttons={[
                    {
                        text: 'Ok',
                        handler: async () => {
                            dispatch(clearErrorsAction());
                        }
                    }
                ]}
            />
        );
    };


    const loadingIndicator = () => {
        return (
            <IonLoading
                isOpen={showLoading}
                message={t("GENERAL.LoadingMessage")}
                translucent
            />
        )
    };

    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route path='/login' render={()=>{
                            console.log("[APP] ROUTER LOGIN // isAuthenticated = ", isAuthenticated);
                            if (!isAuthenticated) {
                                console.log("[APP] Login-Route / -> LOGIN");
                                return <LoginComponent />
                            } else {
                                console.log("[APP] Home-Route / -> HOME");
                                <Redirect to="/home"/>
                            }
                        }} exact/>
                        <Route
                            path='/:tab(home)'
                            render={() => {
                                console.log("[APP] ROUTER HOME // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <HomeComponent/> : <Redirect to="/login"/>
                            }}
                            exact
                        />
                        <Route
                            path='/:tab(modules)'
                            render={() => {
                                console.log("[APP] ROUTER MODULES // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <ModulesComponent forPerson={false}/> : <Redirect to='/login'/>
                            }}
                            exact
                        />

                        <Route
                            path='/:tab(modules)/sales'
                            render={() => {
                                console.log("[APP] ROUTER SALES // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <SalesComponent/> : <Redirect to='/login'/>
                            }}
                            exact
                        />

                        <Route
                            path='/:tab(modules)/sales/person/:personId'
                            render={() => {
                                console.log("[APP] ROUTER SALES PERSON // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <ModulesComponent forPerson={true}/> : <Redirect to='/login'/>
                            }}
                        />

                        <Route
                            path='/:tab(modules)/training/:trainingId'
                            render={() => {
                                console.log("[APP] ROUTER TRAINING // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <TrainingComponent/> : <Redirect to='/login'/>
                            }}
                        />

                        <Route
                            path='/:tab(modules)/session/:sessionId'
                            render={() => {
                                console.log("[APP] ROUTER SESSION // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <SessionComponent/> : <Redirect to='/login'/>
                            }}
                        />

                        <Route
                            path='/:tab(survey)'
                            render={() => {
                                console.log("[APP] ROUTER SURVEY // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <SurveyComponent/> : <Redirect to='/login'/>
                            }}
                            exact
                        />
                        <Route
                            path='/:tab(home)/dominances'
                            render={() => {
                                console.log("[APP] ROUTER DOMINANCES // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <DominancesComponent/> : <Redirect to='/login'/>
                            }}
                            exact
                        />
                        <Route path='/:tab(info)' component={InfoComponent} exact/>
                        <Route path='/:tab(info)/profile' render={() => {
                            console.log("[APP] ROUTER PROFILE // isAuthenticated = ", isAuthenticated);
                            return isAuthenticated ? <ProfileComponent/> : <Redirect to='/login'/>
                        }} exact/>

                        <Route
                            path='/evaluation'
                            render={() => {
                                console.log("[APP] ROUTER EVALUATION // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <EvaluationComponent/> : <Redirect to='/login'/>
                            }}
                            exact
                        />
                        <Route
                            path='/:tab(home)/big5'
                            render={() => {
                                console.log("[APP] ROUTER BIG5 // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <Big5Component/> : <Redirect to='/login'/>
                            }}
                            exact
                        />
                        <Route
                            path='/quiz/:id/:sid/:speed'
                            render={() => {
                                console.log("[APP] ROUTER QUIZ // isAuthenticated = ", isAuthenticated);
                                return isAuthenticated ? <QuizComponent/> : <Redirect to='/login'/>
                            }}
                            exact
                        />
                        <Route path='/registration' component={RegistrationComponent}/>
                        <Route path='/' render={() => {
                            
                            if (!isAuthenticated) {
                                console.log("[APP] Route / -> LOGIN");
                                return <Redirect to='/login'/>
                            } else {
                                console.log("[APP] Route / -> HOME");
                                return <HomeComponent />
                            }
                        }} exact/>
                    </IonRouterOutlet>
                    {isAuthenticated ? TabsBar() : <IonTabBar/>}
                </IonTabs>
            </IonReactRouter>
            <PrivacyModal />
            {errorAlert()}
            {loadingIndicator()}
        </IonApp>
    )
};

export default Mindcodex