import {Network} from "@capacitor/network";
import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/react';
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import style from './Dominances.module.scss';


const DominancesComponent: React.FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const history = useHistory();
    const language = useSelector<AppState, string>(state=>state.system.language);

    useEffect(() => {
        const reloadUser = async () => {
            const network = await Network.getStatus();
            if (network.connected) {
                try {
                    const response = await userApi.getUser(user.id as number, language);
                    dispatch(setUserAction(response.data));
                } catch (e) {
                     if ((e as any).response?.status === 401) {
                        dispatch(clearUserAction() as any);
                        history.replace("/login");
                    }
                    dispatch(setErrorAction(e) as any);
                }
            }
        };

        reloadUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "headerRed"}} />
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <p className={style.title}>{t("DOMINANCES.Title")}</p>
                            {user.personalityType ?
                                <p className={style.text} dangerouslySetInnerHTML={{__html: user.personalityHtml as string}}/> :
                                <p className={style.text}>{t("DOMINANCES.NoData")}</p>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default DominancesComponent;
