import {IonButton, IonCol, IonContent, IonGrid, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonPage, IonRow} from "@ionic/react";
import classNames from "classnames";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {EvaluatedPerson, User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi, userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import AddPerson from "../AddPerson/AddPerson";
import style from "./Sales.module.scss";


const SalesComponent: React.FC = () => {
    const dispatch = useDispatch();
    const language = useSelector<AppState, string>(state => state.system.language);
    const user = useSelector<AppState, User>(state => state.auth.user);
    const history = useHistory();
    const [showAddPersonModal, setShowAddPersonModal] = useState(false);
    const [showListView, setShowListView] = useState(false);
    const [adPersonRemaining, setAddPersonRemaining] = useState(0);
    const {t} = useTranslation();

    const [red_user, setRed_user] = useState<Array<EvaluatedPerson>>([]);
    const [green_user, setGreen_user] = useState<Array<EvaluatedPerson>>([]);
    const [blue_user, setBlue_user] = useState<Array<EvaluatedPerson>>([]);
    const [evaluatedPersons, setEvaluatedPersons] = useState<Array<EvaluatedPerson>>([]);


    useEffect(() => {
        setAddPersonRemaining((user.canEvaluate as number) - (user.hasEvaluated as number));
        getPersons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPersons = async () => {
        try {
            const response = await dataApi.getPersons(language);
            setEvaluatedPersons(response.data);
            const red = [];
            const green = [];
            const blue = [];
            for (let user of response.data) {
                switch (user.personalityType) {
                    case "1":
                    case "2":
                    case "3":
                        red.push(user);
                        break;
                    case "4":
                    case "5":
                    case "6":
                        blue.push(user);
                        break;
                    case "7":
                    case "8":
                    case "9":
                        green.push(user);
                        break;
                }
            }
            setRed_user(red);
            setGreen_user(green);
            setBlue_user(blue);
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const handelAddNewPerson = async (person: EvaluatedPerson) => {
        try {
            dispatch(setShowLoadingAction(true));
            await dataApi.createPerson(language, person);
            setShowListView(false);
            await getPersons();
            const response = await userApi.getUser(user.id as number, language);
            dispatch(setUserAction(response.data));
            dispatch(setShowLoadingAction(false));
            setAddPersonRemaining((response.data.canEvaluate as number) - (response.data.hasEvaluated as number));
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const deletePerson = async (person: EvaluatedPerson) => {
        try {
            dispatch(setShowLoadingAction(true));
            await dataApi.deletePerson(person.id as number, language);
            setShowListView(false);
            getPersons();
            dispatch(setShowLoadingAction(false));
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const getDots = (selectedUsers: Array<EvaluatedPerson>, color: string) => {
        if (selectedUsers.length === 0) {
            return "";
        }

        return (
            <div className={style[color + "_area"]}>
                {selectedUsers.map((evaluatedPerson, i) => {
                    return (
                        <div
                            key={i}
                            className={classNames(
                                {[style["red" + i]]: (evaluatedPerson.personalityType === "1" || evaluatedPerson.personalityType === "2" || evaluatedPerson.personalityType === "3")},
                                {[style["blue" + i]]: (evaluatedPerson.personalityType === "4" || evaluatedPerson.personalityType === "5" || evaluatedPerson.personalityType === "6")},
                                {[style["green" + i]]: (evaluatedPerson.personalityType === "7" || evaluatedPerson.personalityType === "8" || evaluatedPerson.personalityType === "9")},
                                style.dot
                            )}
                            onClick={event => {
                                history.push("/modules/sales/person/" + evaluatedPerson.id);
                            }}
                        >
                            {evaluatedPerson.initials}
                        </div>
                    );
                })}
            </div>
        );
    };


    const teamDiamond = () => {
        return (
            <div className={style.diamond_col}>
                <div className={style.dots_wrapper}>
                    {red_user.length > 0 && getDots(red_user, "red")}
                    {green_user.length > 0 && getDots(green_user, "green")}
                    {blue_user.length > 0 && getDots(blue_user, "blue")}
                </div>

                <div className={style.diamond}/>
            </div>
        );
    };


    const teamList = () => {
        return (
            <IonList className={style.evaluated_person_list}>
                {evaluatedPersons.map((evaluatedPerson, id) => {
                    return (
                        <IonItemSliding key={id}>
                            <IonItem color={"module-inner-background"} className={style.evaluated_person}
                                     lines={"none"} detail={false} onClick={() => {
                                history.push("/modules/sales/person/" + evaluatedPerson.id);
                            }}>
                                <IonLabel>
                                    <div className={style.evaluated_person_inner}>
                                        <div className={style.evaluated_person_text}>
                                            <p>{evaluatedPerson.initials}</p>
                                            {evaluatedPerson.comment && <p>{evaluatedPerson.comment}</p>}
                                        </div>
                                        <div className={classNames(style.list_dot,
                                            {[style.red_list_dot]: (evaluatedPerson.personalityType === "1" || evaluatedPerson.personalityType === "2" || evaluatedPerson.personalityType === "3")},
                                            {[style.blue_list_dot]: (evaluatedPerson.personalityType === "4" || evaluatedPerson.personalityType === "5" || evaluatedPerson.personalityType === "6")},
                                            {[style.green_list_dot]: (evaluatedPerson.personalityType === "7" || evaluatedPerson.personalityType === "8" || evaluatedPerson.personalityType === "9")}
                                        )}/>
                                    </div>
                                </IonLabel>
                            </IonItem>

                            <IonItemOptions side="end" className={style.item_options}>
                                <IonItemOption className={style.item_option} onClick={() => {
                                    deletePerson(evaluatedPerson);
                                }}>{t("GENERAL.Delete")}</IonItemOption>
                            </IonItemOptions>
                        </IonItemSliding>
                    );
                })}
            </IonList>
        )
    };


    return (
        <IonPage>
            <HeaderComponent {...{color: "module", showButton: true, defaultHref: "/modules"}}/>
            <AddPerson onAddPerson={handelAddNewPerson} showModal={showAddPersonModal} setShowModal={setShowAddPersonModal}/>
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {showListView ? teamList() : teamDiamond()}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.button_col}>
                            <IonButton
                                disabled={evaluatedPersons.length === 0}
                                onClick={event => {
                                    setShowListView(!showListView);
                                }}>
                                {!showListView && <IonLabel>{t("SALES.ShowTeam")}</IonLabel>}
                                {showListView && <IonLabel>{t("SALES.ShowDiamond")}</IonLabel>}
                            </IonButton>
                            <IonButton
                                disabled={adPersonRemaining === 0}
                                onClick={event => {
                                    setShowAddPersonModal(true);
                                }}>
                                {t("SALES.AddPerson")}
                            </IonButton>
                            <p className={style.remaining}>{t("SALES.Remaining")}: {adPersonRemaining}</p>
                            <p className={style.info_text}>{t("SALES.Info1")}</p>
                            <p className={style.info_text}>{t("SALES.Info2")}</p>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SalesComponent
