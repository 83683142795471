/* tslint:disable */
/* eslint-disable */
/**
 * Mindcodex
 * API of Mindcodex for app
 *
 * The version of the OpenAPI document: 3.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface EvaluatedPerson
 */
export interface EvaluatedPerson {
    /**
     * 
     * @type {number}
     * @memberof EvaluatedPerson
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EvaluatedPerson
     */
    'initials'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluatedPerson
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluatedPerson
     */
    'organisationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluatedPerson
     */
    'personalityType'?: string;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'search'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'ordering'?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'orderingDirection'?: string;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'itemsOnPage'?: number;
}
/**
 * 
 * @export
 * @interface GetQuizResult200Response
 */
export interface GetQuizResult200Response {
    /**
     * 
     * @type {string}
     * @memberof GetQuizResult200Response
     */
    'lastParticipationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetQuizResult200Response
     */
    'lastParticipationScore'?: number;
}
/**
 * 
 * @export
 * @interface MindCodexValue
 */
export interface MindCodexValue {
    /**
     * 
     * @type {number}
     * @memberof MindCodexValue
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MindCodexValue
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MindCodexValue
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof MindCodexValue
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'iconFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {Array<Training>}
     * @memberof Module
     */
    'training'?: Array<Training>;
}
/**
 * 
 * @export
 * @interface ModuleShort
 */
export interface ModuleShort {
    /**
     * 
     * @type {number}
     * @memberof ModuleShort
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleShort
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PostTrainerUserList200Response
 */
export interface PostTrainerUserList200Response {
    /**
     * 
     * @type {string}
     * @memberof PostTrainerUserList200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Privacy
 */
export interface Privacy {
    /**
     * 
     * @type {string}
     * @memberof Privacy
     */
    'html'?: string;
    /**
     * 
     * @type {number}
     * @memberof Privacy
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface Quiz
 */
export interface Quiz {
    /**
     * 
     * @type {number}
     * @memberof Quiz
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Quiz
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Quiz
     */
    'introText'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Quiz
     */
    'answers'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Quiz
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof Quiz
     */
    'speed_normal'?: number;
    /**
     * 
     * @type {number}
     * @memberof Quiz
     */
    'speed_fast'?: number;
    /**
     * 
     * @type {Array<QuizQuestionsInner>}
     * @memberof Quiz
     */
    'questions'?: Array<QuizQuestionsInner>;
}
/**
 * 
 * @export
 * @interface QuizQuestionsInner
 */
export interface QuizQuestionsInner {
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionsInner
     */
    'question'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuizQuestionsInner
     */
    'correctAnswerIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionsInner
     */
    'video'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuizQuestionsInner
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface QuizResult
 */
export interface QuizResult {
    /**
     * 
     * @type {number}
     * @memberof QuizResult
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuizResult
     */
    'participationScore'?: number;
    /**
     * 
     * @type {Array<QuizResultScoreInner>}
     * @memberof QuizResult
     */
    'score'?: Array<QuizResultScoreInner>;
}
/**
 * 
 * @export
 * @interface QuizResultScoreInner
 */
export interface QuizResultScoreInner {
    /**
     * 
     * @type {number}
     * @memberof QuizResultScoreInner
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof QuizResultScoreInner
     */
    'answer'?: string;
    /**
     * 
     * @type {number}
     * @memberof QuizResultScoreInner
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'introText'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'endText'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'videoUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'videoFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'imageFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'audioUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'audioFile'?: string;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    'version'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    'isRead'?: boolean;
}
/**
 * 
 * @export
 * @interface SessionShort
 */
export interface SessionShort {
    /**
     * 
     * @type {number}
     * @memberof SessionShort
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionShort
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionShort
     */
    'introText'?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionShort
     */
    'version'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SessionShort
     */
    'isRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionShort
     */
    'isBlank'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SessionShort
     */
    'contentType'?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionShort
     */
    'quizId'?: number;
}
/**
 * 
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * 
     * @type {Array<SurveyQuestionsInner>}
     * @memberof Survey
     */
    'questions'?: Array<SurveyQuestionsInner>;
}
/**
 * 
 * @export
 * @interface SurveyQuestionsInner
 */
export interface SurveyQuestionsInner {
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionsInner
     */
    'question'?: string;
    /**
     * 
     * @type {Array<SurveyQuestionsInnerAnswersInner>}
     * @memberof SurveyQuestionsInner
     */
    'answers'?: Array<SurveyQuestionsInnerAnswersInner>;
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionsInner
     */
    'selectedAnswer'?: number;
}
/**
 * 
 * @export
 * @interface SurveyQuestionsInnerAnswersInner
 */
export interface SurveyQuestionsInnerAnswersInner {
    /**
     * 
     * @type {number}
     * @memberof SurveyQuestionsInnerAnswersInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyQuestionsInnerAnswersInner
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface TrainerUserList
 */
export interface TrainerUserList {
    /**
     * 
     * @type {Array<UserShort>}
     * @memberof TrainerUserList
     */
    'userList'?: Array<UserShort>;
    /**
     * 
     * @type {number}
     * @memberof TrainerUserList
     */
    'maxPage'?: number;
}
/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {number}
     * @memberof Training
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'contentType'?: string;
    /**
     * 
     * @type {Array<SessionShort>}
     * @memberof Training
     */
    'sessions'?: Array<SessionShort>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'changedOwnType'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'changedBigFive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'typeRed'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'typeGreen'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'typeBlue'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'subTypeRed'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'subTypeGreen'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'subTypeBlue'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'personalityType'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'personalityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'personalityHtml'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'canEvaluate'?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'hasEvaluated'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'token'?: string;
    /**
     * 
     * @type {Array<MindCodexValue>}
     * @memberof User
     */
    'big5Values'?: Array<MindCodexValue>;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'showSurvey'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'showResult'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isTrainer'?: boolean;
}
/**
 * 
 * @export
 * @interface UserShort
 */
export interface UserShort {
    /**
     * 
     * @type {number}
     * @memberof UserShort
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'surname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserShort
     */
    'typeRed'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserShort
     */
    'typeGreen'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserShort
     */
    'typeBlue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserShort
     */
    'showResult'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'group'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'personalityName'?: string;
    /**
     * 
     * @type {Array<MindCodexValue>}
     * @memberof UserShort
     */
    'big5Values'?: Array<MindCodexValue>;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'info'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserShort
     */
    'state'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserShort
     */
    'modules'?: Array<number>;
}

/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new person
         * @param {string} lang 
         * @param {EvaluatedPerson} evaluatedPerson person
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (lang: string, evaluatedPerson: EvaluatedPerson, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createPerson', 'lang', lang)
            // verify required parameter 'evaluatedPerson' is not null or undefined
            assertParamExists('createPerson', 'evaluatedPerson', evaluatedPerson)
            const localVarPath = `/evaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluatedPerson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson: async (personId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('deletePerson', 'personId', personId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deletePerson', 'lang', lang)
            const localVarPath = `/evaluation/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of  module for specific person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleForPerson: async (personId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('getModuleForPerson', 'personId', personId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getModuleForPerson', 'lang', lang)
            const localVarPath = `/module/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules: async (lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getModules', 'lang', lang)
            const localVarPath = `/module`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {number} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModulesShort: async (lang: string, trainerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getModulesShort', 'lang', lang)
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('getModulesShort', 'trainerId', trainerId)
            const localVarPath = `/module/short`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (trainerId !== undefined) {
                localVarQueryParameter['trainerId'] = trainerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of evaluated persons
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersons: async (lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getPersons', 'lang', lang)
            const localVarPath = `/evaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a session
         * @param {number} sessionId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (sessionId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getSession', 'sessionId', sessionId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSession', 'lang', lang)
            const localVarPath = `/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the json file to build the survey frontend
         * @summary get survey json
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurvey: async (lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getSurvey', 'lang', lang)
            const localVarPath = `/survey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list with mind codex values
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValues: async (lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getValues', 'lang', lang)
            const localVarPath = `/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary send survey
         * @param {number} userId 
         * @param {string} lang 
         * @param {Survey} survey survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSurvey: async (userId: number, lang: string, survey: Survey, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveSurvey', 'userId', userId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('saveSurvey', 'lang', lang)
            // verify required parameter 'survey' is not null or undefined
            assertParamExists('saveSurvey', 'survey', survey)
            const localVarPath = `/survey/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(survey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * set a session as read
         * @summary update a session
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSessionAsRead: async (sessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('setSessionAsRead', 'sessionId', sessionId)
            const localVarPath = `/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (personId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('updatePerson', 'personId', personId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updatePerson', 'lang', lang)
            const localVarPath = `/evaluation/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new person
         * @param {string} lang 
         * @param {EvaluatedPerson} evaluatedPerson person
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(lang: string, evaluatedPerson: EvaluatedPerson, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluatedPerson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(lang, evaluatedPerson, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerson(personId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerson(personId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of  module for specific person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleForPerson(personId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Module>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleForPerson(personId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModules(lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Module>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModules(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {number} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModulesShort(lang: string, trainerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModuleShort>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModulesShort(lang, trainerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of evaluated persons
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersons(lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvaluatedPerson>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersons(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a session
         * @param {number} sessionId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(sessionId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Session>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(sessionId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get the json file to build the survey frontend
         * @summary get survey json
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSurvey(lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSurvey(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list with mind codex values
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getValues(lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MindCodexValue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getValues(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary send survey
         * @param {number} userId 
         * @param {string} lang 
         * @param {Survey} survey survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSurvey(userId: number, lang: string, survey: Survey, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSurvey(userId, lang, survey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * set a session as read
         * @summary update a session
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSessionAsRead(sessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSessionAsRead(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(personId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvaluatedPerson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(personId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new person
         * @param {string} lang 
         * @param {EvaluatedPerson} evaluatedPerson person
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(lang: string, evaluatedPerson: EvaluatedPerson, options?: any): AxiosPromise<EvaluatedPerson> {
            return localVarFp.createPerson(lang, evaluatedPerson, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerson(personId: number, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerson(personId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of  module for specific person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleForPerson(personId: number, lang: string, options?: any): AxiosPromise<Array<Module>> {
            return localVarFp.getModuleForPerson(personId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModules(lang: string, options?: any): AxiosPromise<Array<Module>> {
            return localVarFp.getModules(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list with module
         * @param {string} lang 
         * @param {number} trainerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModulesShort(lang: string, trainerId: number, options?: any): AxiosPromise<Array<ModuleShort>> {
            return localVarFp.getModulesShort(lang, trainerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of evaluated persons
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersons(lang: string, options?: any): AxiosPromise<Array<EvaluatedPerson>> {
            return localVarFp.getPersons(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a session
         * @param {number} sessionId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(sessionId: number, lang: string, options?: any): AxiosPromise<Session> {
            return localVarFp.getSession(sessionId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * get the json file to build the survey frontend
         * @summary get survey json
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSurvey(lang: string, options?: any): AxiosPromise<Survey> {
            return localVarFp.getSurvey(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list with mind codex values
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getValues(lang: string, options?: any): AxiosPromise<Array<MindCodexValue>> {
            return localVarFp.getValues(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary send survey
         * @param {number} userId 
         * @param {string} lang 
         * @param {Survey} survey survey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSurvey(userId: number, lang: string, survey: Survey, options?: any): AxiosPromise<void> {
            return localVarFp.saveSurvey(userId, lang, survey, options).then((request) => request(axios, basePath));
        },
        /**
         * set a session as read
         * @summary update a session
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSessionAsRead(sessionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.setSessionAsRead(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a person
         * @param {number} personId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(personId: number, lang: string, options?: any): AxiosPromise<EvaluatedPerson> {
            return localVarFp.updatePerson(personId, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * 
     * @summary Create a new person
     * @param {string} lang 
     * @param {EvaluatedPerson} evaluatedPerson person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public createPerson(lang: string, evaluatedPerson: EvaluatedPerson, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).createPerson(lang, evaluatedPerson, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a person
     * @param {number} personId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public deletePerson(personId: number, lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).deletePerson(personId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of  module for specific person
     * @param {number} personId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getModuleForPerson(personId: number, lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getModuleForPerson(personId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list with module
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getModules(lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getModules(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list with module
     * @param {string} lang 
     * @param {number} trainerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getModulesShort(lang: string, trainerId: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getModulesShort(lang, trainerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of evaluated persons
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getPersons(lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getPersons(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a session
     * @param {number} sessionId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getSession(sessionId: number, lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getSession(sessionId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get the json file to build the survey frontend
     * @summary get survey json
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getSurvey(lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getSurvey(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list with mind codex values
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getValues(lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).getValues(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary send survey
     * @param {number} userId 
     * @param {string} lang 
     * @param {Survey} survey survey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public saveSurvey(userId: number, lang: string, survey: Survey, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).saveSurvey(userId, lang, survey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * set a session as read
     * @summary update a session
     * @param {number} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public setSessionAsRead(sessionId: number, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).setSessionAsRead(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a person
     * @param {number} personId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public updatePerson(personId: number, lang: string, options?: AxiosRequestConfig) {
        return DataApiFp(this.configuration).updatePerson(personId, lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MiscApi - axios parameter creator
 * @export
 */
export const MiscApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns privacy
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacy: async (lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getPrivacy', 'lang', lang)
            const localVarPath = `/privacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns OK if alive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MiscApi - functional programming interface
 * @export
 */
export const MiscApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MiscApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns privacy
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivacy(lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Privacy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivacy(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns OK if alive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MiscApi - factory interface
 * @export
 */
export const MiscApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MiscApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns privacy
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivacy(lang: string, options?: any): AxiosPromise<Privacy> {
            return localVarFp.getPrivacy(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns OK if alive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MiscApi - object-oriented interface
 * @export
 * @class MiscApi
 * @extends {BaseAPI}
 */
export class MiscApi extends BaseAPI {
    /**
     * 
     * @summary Returns privacy
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscApi
     */
    public getPrivacy(lang: string, options?: AxiosRequestConfig) {
        return MiscApiFp(this.configuration).getPrivacy(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns OK if alive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MiscApi
     */
    public health(options?: AxiosRequestConfig) {
        return MiscApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuizApi - axios parameter creator
 * @export
 */
export const QuizApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get the json file to build the quiz frontend
         * @summary get quiz json
         * @param {number} quizId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuiz: async (quizId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizId' is not null or undefined
            assertParamExists('getQuiz', 'quizId', quizId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getQuiz', 'lang', lang)
            const localVarPath = `/quiz/{quizId}`
                .replace(`{${"quizId"}}`, encodeURIComponent(String(quizId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get quiz result
         * @param {number} quizId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizResult: async (quizId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizId' is not null or undefined
            assertParamExists('getQuizResult', 'quizId', quizId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getQuizResult', 'userId', userId)
            const localVarPath = `/quiz/{quizId}/result/{userId}`
                .replace(`{${"quizId"}}`, encodeURIComponent(String(quizId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary post quiz results
         * @param {number} quizId 
         * @param {number} userId 
         * @param {QuizResult} quizResult Quiz Results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuizResult: async (quizId: number, userId: number, quizResult: QuizResult, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'quizId' is not null or undefined
            assertParamExists('postQuizResult', 'quizId', quizId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postQuizResult', 'userId', userId)
            // verify required parameter 'quizResult' is not null or undefined
            assertParamExists('postQuizResult', 'quizResult', quizResult)
            const localVarPath = `/quiz/{quizId}/result/{userId}`
                .replace(`{${"quizId"}}`, encodeURIComponent(String(quizId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizResult, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuizApi - functional programming interface
 * @export
 */
export const QuizApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuizApiAxiosParamCreator(configuration)
    return {
        /**
         * get the json file to build the quiz frontend
         * @summary get quiz json
         * @param {number} quizId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuiz(quizId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quiz>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuiz(quizId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get quiz result
         * @param {number} quizId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuizResult(quizId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuizResult200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuizResult(quizId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary post quiz results
         * @param {number} quizId 
         * @param {number} userId 
         * @param {QuizResult} quizResult Quiz Results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postQuizResult(quizId: number, userId: number, quizResult: QuizResult, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postQuizResult(quizId, userId, quizResult, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuizApi - factory interface
 * @export
 */
export const QuizApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuizApiFp(configuration)
    return {
        /**
         * get the json file to build the quiz frontend
         * @summary get quiz json
         * @param {number} quizId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuiz(quizId: number, lang: string, options?: any): AxiosPromise<Quiz> {
            return localVarFp.getQuiz(quizId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get quiz result
         * @param {number} quizId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuizResult(quizId: number, userId: number, options?: any): AxiosPromise<GetQuizResult200Response> {
            return localVarFp.getQuizResult(quizId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary post quiz results
         * @param {number} quizId 
         * @param {number} userId 
         * @param {QuizResult} quizResult Quiz Results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postQuizResult(quizId: number, userId: number, quizResult: QuizResult, options?: any): AxiosPromise<void> {
            return localVarFp.postQuizResult(quizId, userId, quizResult, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuizApi - object-oriented interface
 * @export
 * @class QuizApi
 * @extends {BaseAPI}
 */
export class QuizApi extends BaseAPI {
    /**
     * get the json file to build the quiz frontend
     * @summary get quiz json
     * @param {number} quizId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public getQuiz(quizId: number, lang: string, options?: AxiosRequestConfig) {
        return QuizApiFp(this.configuration).getQuiz(quizId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get quiz result
     * @param {number} quizId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public getQuizResult(quizId: number, userId: number, options?: AxiosRequestConfig) {
        return QuizApiFp(this.configuration).getQuizResult(quizId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary post quiz results
     * @param {number} quizId 
     * @param {number} userId 
     * @param {QuizResult} quizResult Quiz Results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public postQuizResult(quizId: number, userId: number, quizResult: QuizResult, options?: AxiosRequestConfig) {
        return QuizApiFp(this.configuration).postQuizResult(quizId, userId, quizResult, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrainerApi - axios parameter creator
 * @export
 */
export const TrainerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainerUser: async (trainerId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('deleteTrainerUser', 'trainerId', trainerId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTrainerUser', 'userId', userId)
            const localVarPath = `/trainer/{trainerId}/user/{userId}`
                .replace(`{${"trainerId"}}`, encodeURIComponent(String(trainerId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user list for trainer
         * @param {number} trainerId 
         * @param {string} lang 
         * @param {Filter} filter filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainerUserList: async (trainerId: number, lang: string, filter: Filter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('getTrainerUserList', 'trainerId', trainerId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getTrainerUserList', 'lang', lang)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('getTrainerUserList', 'filter', filter)
            const localVarPath = `/trainer/{trainerId}/user/list`
                .replace(`{${"trainerId"}}`, encodeURIComponent(String(trainerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create a list of users for trainer
         * @param {number} trainerId 
         * @param {TrainerUserList} trainerUserList user list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrainerUserList: async (trainerId: number, trainerUserList: TrainerUserList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('postTrainerUserList', 'trainerId', trainerId)
            // verify required parameter 'trainerUserList' is not null or undefined
            assertParamExists('postTrainerUserList', 'trainerUserList', trainerUserList)
            const localVarPath = `/trainer/{trainerId}/user`
                .replace(`{${"trainerId"}}`, encodeURIComponent(String(trainerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainerUserList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {UserShort} userShort user short
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainerUser: async (trainerId: number, userId: number, userShort: UserShort, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trainerId' is not null or undefined
            assertParamExists('updateTrainerUser', 'trainerId', trainerId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTrainerUser', 'userId', userId)
            // verify required parameter 'userShort' is not null or undefined
            assertParamExists('updateTrainerUser', 'userShort', userShort)
            const localVarPath = `/trainer/{trainerId}/user/{userId}`
                .replace(`{${"trainerId"}}`, encodeURIComponent(String(trainerId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userShort, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainerApi - functional programming interface
 * @export
 */
export const TrainerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrainerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrainerUser(trainerId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrainerUser(trainerId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user list for trainer
         * @param {number} trainerId 
         * @param {string} lang 
         * @param {Filter} filter filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrainerUserList(trainerId: number, lang: string, filter: Filter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainerUserList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainerUserList(trainerId, lang, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create a list of users for trainer
         * @param {number} trainerId 
         * @param {TrainerUserList} trainerUserList user list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTrainerUserList(trainerId: number, trainerUserList: TrainerUserList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTrainerUserList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTrainerUserList(trainerId, trainerUserList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {UserShort} userShort user short
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrainerUser(trainerId: number, userId: number, userShort: UserShort, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrainerUser(trainerId, userId, userShort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrainerApi - factory interface
 * @export
 */
export const TrainerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrainerApiFp(configuration)
    return {
        /**
         * 
         * @summary delete a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrainerUser(trainerId: number, userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrainerUser(trainerId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user list for trainer
         * @param {number} trainerId 
         * @param {string} lang 
         * @param {Filter} filter filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrainerUserList(trainerId: number, lang: string, filter: Filter, options?: any): AxiosPromise<TrainerUserList> {
            return localVarFp.getTrainerUserList(trainerId, lang, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create a list of users for trainer
         * @param {number} trainerId 
         * @param {TrainerUserList} trainerUserList user list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTrainerUserList(trainerId: number, trainerUserList: TrainerUserList, options?: any): AxiosPromise<PostTrainerUserList200Response> {
            return localVarFp.postTrainerUserList(trainerId, trainerUserList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update a user for trainer
         * @param {number} trainerId 
         * @param {number} userId 
         * @param {UserShort} userShort user short
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrainerUser(trainerId: number, userId: number, userShort: UserShort, options?: any): AxiosPromise<void> {
            return localVarFp.updateTrainerUser(trainerId, userId, userShort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrainerApi - object-oriented interface
 * @export
 * @class TrainerApi
 * @extends {BaseAPI}
 */
export class TrainerApi extends BaseAPI {
    /**
     * 
     * @summary delete a user for trainer
     * @param {number} trainerId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public deleteTrainerUser(trainerId: number, userId: number, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).deleteTrainerUser(trainerId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user list for trainer
     * @param {number} trainerId 
     * @param {string} lang 
     * @param {Filter} filter filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public getTrainerUserList(trainerId: number, lang: string, filter: Filter, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).getTrainerUserList(trainerId, lang, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create a list of users for trainer
     * @param {number} trainerId 
     * @param {TrainerUserList} trainerUserList user list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public postTrainerUserList(trainerId: number, trainerUserList: TrainerUserList, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).postTrainerUserList(trainerId, trainerUserList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update a user for trainer
     * @param {number} trainerId 
     * @param {number} userId 
     * @param {UserShort} userShort user short
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainerApi
     */
    public updateTrainerUser(trainerId: number, userId: number, userShort: UserShort, options?: AxiosRequestConfig) {
        return TrainerApiFp(this.configuration).updateTrainerUser(trainerId, userId, userShort, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary register a new user
         * @param {string} lang 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (lang: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('createUser', 'lang', lang)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('createUser', 'user', user)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (lang: string, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('deleteUser', 'lang', lang)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a specific user
         * @param {number} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: number, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('getUser', 'lang', lang)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary log in an user
         * @param {string} lang 
         * @param {User} user user credetials
         * @param {boolean} [isTrainerApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (lang: string, user: User, isTrainerApp?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('login', 'lang', lang)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('login', 'user', user)
            const localVarPath = `/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (isTrainerApp !== undefined) {
                localVarQueryParameter['isTrainerApp'] = isTrainerApp;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary double opt-in
         * @param {string} lang 
         * @param {number} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optInUser: async (lang: string, userId: number, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('optInUser', 'lang', lang)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('optInUser', 'userId', userId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('optInUser', 'token', token)
            const localVarPath = `/users/opt-in/{userId}/{token}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary request a new password
         * @param {string} mail 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPassword: async (mail: string, lang: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('requestPassword', 'mail', mail)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('requestPassword', 'lang', lang)
            const localVarPath = `/password/{mail}`
                .replace(`{${"mail"}}`, encodeURIComponent(String(mail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update password
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (lang: string, userId: number, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updatePassword', 'lang', lang)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePassword', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updatePassword', 'user', user)
            const localVarPath = `/password/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (lang: string, userId: number, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('updateUser', 'lang', lang)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary register a new user
         * @param {string} lang 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(lang: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(lang, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(lang: string, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(lang, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a specific user
         * @param {number} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: number, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary log in an user
         * @param {string} lang 
         * @param {User} user user credetials
         * @param {boolean} [isTrainerApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(lang: string, user: User, isTrainerApp?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(lang, user, isTrainerApp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary double opt-in
         * @param {string} lang 
         * @param {number} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optInUser(lang: string, userId: number, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optInUser(lang, userId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary request a new password
         * @param {string} mail 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestPassword(mail: string, lang: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestPassword(mail, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update password
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(lang: string, userId: number, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(lang, userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(lang: string, userId: number, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(lang, userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary register a new user
         * @param {string} lang 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(lang: string, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(lang, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(lang: string, userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(lang, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a specific user
         * @param {number} userId 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, lang: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary log in an user
         * @param {string} lang 
         * @param {User} user user credetials
         * @param {boolean} [isTrainerApp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(lang: string, user: User, isTrainerApp?: boolean, options?: any): AxiosPromise<User> {
            return localVarFp.login(lang, user, isTrainerApp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary double opt-in
         * @param {string} lang 
         * @param {number} userId 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optInUser(lang: string, userId: number, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.optInUser(lang, userId, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary request a new password
         * @param {string} mail 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPassword(mail: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.requestPassword(mail, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update password
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(lang: string, userId: number, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.updatePassword(lang, userId, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates a specific user
         * @param {string} lang 
         * @param {number} userId 
         * @param {User} user user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(lang: string, userId: number, user: User, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(lang, userId, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary register a new user
     * @param {string} lang 
     * @param {User} user user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(lang: string, user: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(lang, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a specific user
     * @param {string} lang 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(lang: string, userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(lang, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a specific user
     * @param {number} userId 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: number, lang: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userId, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary log in an user
     * @param {string} lang 
     * @param {User} user user credetials
     * @param {boolean} [isTrainerApp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public login(lang: string, user: User, isTrainerApp?: boolean, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).login(lang, user, isTrainerApp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary double opt-in
     * @param {string} lang 
     * @param {number} userId 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public optInUser(lang: string, userId: number, token: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).optInUser(lang, userId, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary request a new password
     * @param {string} mail 
     * @param {string} lang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public requestPassword(mail: string, lang: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).requestPassword(mail, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update password
     * @param {string} lang 
     * @param {number} userId 
     * @param {User} user user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePassword(lang: string, userId: number, user: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePassword(lang, userId, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates a specific user
     * @param {string} lang 
     * @param {number} userId 
     * @param {User} user user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(lang: string, userId: number, user: User, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(lang, userId, user, options).then((request) => request(this.axios, this.basePath));
    }
}


