import {CLEAR_DATA, DataState, SET_LAST_MODULE, SET_LAST_TRAINING, SET_SESSION_KEYS, SET_STORED_SESSION_KEYS, SET_SURVEY, SET_SURVEY_TIME} from "./types";

export const setLastTrainingAction = (training: DataState["lastTraining"]) => {
    return {
        type: SET_LAST_TRAINING,
        payload: training
    }
};

export const setLastModuleAction = (module: DataState["lastModule"]) => {
    return {
        type: SET_LAST_MODULE,
        payload: module
    }
};

export const setSessionKeysAction = (keys: DataState["sessionKeys"]) => {
    return {
        type: SET_SESSION_KEYS,
        payload: keys
    }
};

export const setStoredSessionKeysAction = (keys: DataState["sessionKeys"]) => {
    return {
        type: SET_STORED_SESSION_KEYS,
        payload: keys
    }
};

export const deleteStoredSessionAction = (id: string, sessionKeys: DataState["sessionKeys"]) => {
    const newSessionKeys = sessionKeys;
    for (let i = 0; i < newSessionKeys.length; i++) {
        if (newSessionKeys[i].key === "Session-" + id) {
            newSessionKeys.splice(i, 1);
            break;
        }
    }

    return {
        type: SET_STORED_SESSION_KEYS,
        payload: newSessionKeys
    }
};

export const setSurveyAction = (value: DataState["survey"]) => {
    return {
        type: SET_SURVEY,
        payload: value
    }
}
export const setSurveyTimeAction = (value: DataState["surveyTime"]) => {
    return {
        type: SET_SURVEY_TIME,
        payload: value
    }
}

export const clearDataStateAction = () => {
    return {
        type: CLEAR_DATA
    }
}
