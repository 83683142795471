import {IonButton, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonRow} from '@ionic/react';
import classNames from "classnames";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {MindCodexValue, User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi, userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Big5.module.scss';

const Big5Component: React.FC = () => {
    const dispatch = useDispatch();
    const language = useSelector<AppState, string>(state => state.system.language);
    const user = useSelector<AppState, User>(state => state.auth.user);
    const {t} = useTranslation();
    const [selectedValues, setSelectedValues] = useState<Array<MindCodexValue>>([]);
    const [big5, setBig5] = useState<Array<MindCodexValue>>([]);
    const [showReadMore, setShowReadMore] = useState<boolean>(false);
    const history = useHistory();


    useEffect(() => {
        const loadBig5 = async () => {
            dispatch(setShowLoadingAction(true));
            try {
                const response = await dataApi.getValues(language);
                const sortedList = sortList(response.data);
                loadValuesFromUser(sortedList);
            } catch (e) {
                dispatch(setShowLoadingAction(false));
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
            }
        };

        const loadValuesFromUser = async (sortedList: Array<MindCodexValue>) => {
            try {
                const response = await userApi.getUser(user.id as number, language);
                if (response.data.big5Values) {
                    setSelectedValues(sortList(response.data.big5Values as Array<MindCodexValue>));
                    for (let i = 0; i < response.data.big5Values.length; i++) {
                        for (let k = 0; k < sortedList.length; k++) {
                            if (response.data.big5Values[i].id === sortedList[k].id) {
                                sortedList.splice(k, 1);
                                break;
                            }
                        }
                    }
                }

                dispatch(setUserAction(response.data));
                dispatch(setShowLoadingAction(false));
            } catch (e) {
                dispatch(setShowLoadingAction(false));
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
            }

            setBig5(sortedList);
            dispatch(setShowLoadingAction(false));
        };

        loadBig5();
    }, [language]);


    const reorderItems = (i: number, add: boolean) => {
        if (selectedValues.length === 5 && add) {
            dispatch(setErrorAction({
                response: {
                    data: {
                        name: t("BIG5.ToManySelectedErrorHeader"),
                        message: t("BIG5.ToManySelectedErrorMessage"),
                    },
                    code: 0
                }
            }) as any);
            return;
        }
        let addList = add ? [...selectedValues] : [...big5];
        let removeList = add ? [...big5] : [...selectedValues];
        addList.push(removeList[i]);
        removeList.splice(i, 1);
        addList = sortList(addList);
        removeList = sortList(removeList);
        setSelectedValues(add ? addList : removeList);
        setBig5(add ? removeList : addList);
    };


    const sortList = (list: Array<MindCodexValue>) => {
        list.sort(function (a, b) {
            // @ts-ignore
            if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
                return -1;
            }
            // @ts-ignore
            if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
                return 1;
            }
            return 0;
        });
        return list;
    };

    const storeBig5 = async () => {
        try {
            let countRed = 0;
            let countGreen = 0;
            let countBlue = 0;
            for (let item of selectedValues) {
                switch (item.code) {
                    case 1:
                        countRed++;
                        break;
                    case 2:
                        countGreen++;
                        break;
                    case 3:
                        countBlue++;
                        break;
                }
            }
            user.subTypeRed = countRed;
            user.subTypeGreen = countGreen;
            user.subTypeBlue = countBlue;
            user.changedBigFive = true;
            user.big5Values = selectedValues;

            const response = await userApi.updateUser(language, user.id as number, user);
            dispatch(setUserAction(response.data));
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const getPersonalityTextKey = (id: number) => {
        const html = {__html: ""};
        if (user.subTypeRed != null && user.subTypeGreen != null && user.subTypeBlue != null) {
            if (user.subTypeRed >= 3 && user.subTypeGreen <= 1 && user.subTypeBlue <= 1) {
                html.__html = t("BIG5.Red" + id);
            }
            if (user.subTypeGreen >= 3 && user.subTypeRed <= 1 && user.subTypeBlue <= 1) {
                html.__html = t("BIG5.Green" + id);
            }
            if (user.subTypeBlue >= 3 && user.subTypeGreen <= 1 && user.subTypeRed <= 1) {
                html.__html = t("BIG5.Blue" + id);
            }
            if (user.subTypeRed === 3 && user.subTypeBlue === 0 && user.subTypeGreen === 2) {
                html.__html = t("BIG5.RedGreen" + id);
            }
            if (user.subTypeRed === 2 && user.subTypeBlue === 0 && user.subTypeGreen === 3) {
                html.__html = t("BIG5.RedGreen" + id);
            }
            if (user.subTypeRed === 3 && user.subTypeBlue === 2 && user.subTypeGreen === 0) {
                html.__html = t("BIG5.RedBlue" + id);
            }
            if (user.subTypeRed === 2 && user.subTypeBlue === 3 && user.subTypeGreen === 0) {
                html.__html = t("BIG5.RedBlue" + id);
            }
            if (user.subTypeRed === 0 && user.subTypeBlue === 3 && user.subTypeGreen === 2) {
                html.__html = t("BIG5.BlueGreen" + id);
            }
            if (user.subTypeRed === 0 && user.subTypeBlue === 2 && user.subTypeGreen === 3) {
                html.__html = t("BIG5.BlueGreen" + id);
            }
            if (user.subTypeRed === 2 && user.subTypeBlue === 2 && user.subTypeGreen === 1) {
                html.__html = t("BIG5.Triplet" + id);
            }
            if (user.subTypeRed === 2 && user.subTypeBlue === 1 && user.subTypeGreen === 2) {
                html.__html = t("BIG5.Triplet" + id);
            }
            if (user.subTypeRed === 1 && user.subTypeBlue === 2 && user.subTypeGreen === 2) {
                html.__html = t("BIG5.Triplet" + id);
            }
        }
        return <p dangerouslySetInnerHTML={html}/>;
    };


    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "headerRed"}}/>
            <IonContent className={style.content}>
                <IonGrid className={style.grid}>
                    <IonRow>
                        <IonCol className={style.title_col}>
                            <p>{t("BIG5.Title")}</p>
                            <p>{t("BIG5.TitleText")}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p className={style.description}>{t("BIG5.Description")}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.big5_wrapper}>
                            <IonRow>
                                <IonCol>
                                    <h1 className={style.header}>{t('BIG5.Big5')}</h1>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList className={style.big5_list}>
                                        {selectedValues.map((item, i) => {
                                            return (
                                                <IonItem key={i} className={style.big5_item} onClick={() => {
                                                    reorderItems(i, false);
                                                }}>
                                                    <IonLabel>{item.name}</IonLabel>
                                                    <div className={style.radioButtonSelected}/>
                                                </IonItem>
                                            );
                                        })}
                                    </IonList>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className={classNames(style.select_buttons_col, {[style.select_buttons_col_border]: selectedValues.length === 0})}>
                                    {selectedValues.length === 0 && <p className={style.list_header}>{t("BIG5.Select5Values")}</p>}
                                    {selectedValues.length >= 1 && selectedValues.length < 4 &&
                                    <p className={style.list_header}>{t("BIG5.SelectValues1")} {5 - selectedValues.length} {t("BIG5.SelectValues2")}</p>}
                                    {selectedValues.length === 4 && <p className={style.list_header}>{t("BIG5.SelectValues1")} {5 - selectedValues.length} {t("BIG5.SelectValues3")}</p>}
                                    <IonButton disabled={selectedValues.length < 5 || user.changedBigFive || user.big5Values === selectedValues} onClick={() => {
                                        storeBig5()
                                    }}>{t("BIG5.SelectValuesButton")}</IonButton>
                                    <div className={classNames(style.progressbar, {[style.progressbar_selected]: selectedValues.length > 0})}>
                                        <div className={classNames({[style.selected]: selectedValues.length >= 1})}/>
                                        <div className={classNames({[style.selected]: selectedValues.length >= 2})}/>
                                        <div className={classNames({[style.selected]: selectedValues.length >= 3})}/>
                                        <div className={classNames({[style.selected]: selectedValues.length >= 4})}/>
                                        <div className={classNames({[style.selected]: selectedValues.length >= 5})}/>
                                    </div>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {getPersonalityTextKey(1)}
                                    <p className={"personality_text"}>{t("BIG5.FillText")}</p>
                                    {showReadMore && getPersonalityTextKey(2)}
                                    {!showReadMore && <p className={style.read_more} onClick={() => {
                                        setShowReadMore(true);
                                    }}>{t("BIG5.ReadMore")}</p>}
                                    {showReadMore && <p className={style.read_more} onClick={() => {
                                        setShowReadMore(false);
                                    }}>{t("BIG5.ReadLess")}</p>}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList className={classNames(style.big5_list, style.big5_list_scroll)}>
                                        {big5.map((item, i) => {
                                            return (
                                                <IonItem key={i} className={style.big5_item} onClick={() => {
                                                    reorderItems(i, true);
                                                }}>
                                                    <IonLabel><p className={style.list_item}>{item.name}</p></IonLabel>
                                                    <div className={style.radioButton}/>
                                                </IonItem>
                                            );
                                        })}
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Big5Component;
