import {IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonRow, IonSelect, IonSelectOption, IonTextarea} from "@ionic/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {HeaderComponent} from "../../components/Header/Header";
import style from './AddPerson.module.scss';

const AddPersonComponent: React.FC<any> = ({onAddPerson, showModal, setShowModal}) => {

    const {t} = useTranslation();
    const [initials, setInitials] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [personalityType, setPersonalityType] = useState<string>("");

    return (
        <IonModal isOpen={showModal}>
            <HeaderComponent {...{color: "module"}}/>
            <IonGrid className={style.grid}>
                <IonRow>
                    <IonCol>
                        <IonItem className={style.item} lines={"none"}>
                            <IonLabel position={"stacked"}>{t("ADDPERSON.Initials")}</IonLabel>
                            <IonInput type={"text"} onIonChange={event => {
                                setInitials(event.detail.value as string);
                            }}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className={style.item} lines={"none"}>
                            <IonLabel position={"stacked"}>{t("ADDPERSON.Comment")}</IonLabel>
                            <IonTextarea rows={3} onIonChange={event => {
                                setComment(event.detail.value as string);
                            }}/>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem className={style.item} lines={"none"}>
                            <IonLabel>{t("ADDPERSON.PersonalityType")}</IonLabel>
                            <IonSelect interface={"action-sheet"} onIonChange={event => {
                                setPersonalityType(event.detail.value as string);
                            }}>
                                <IonSelectOption value={"1"}>{t("ADDPERSON.PersonalityTypes.Type1")}</IonSelectOption>
                                <IonSelectOption value={"7"}>{t("ADDPERSON.PersonalityTypes.Type2")}</IonSelectOption>
                                <IonSelectOption value={"4"}>{t("ADDPERSON.PersonalityTypes.Type3")}</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton expand={"block"}
                                   disabled={initials === "" || personalityType === ""}
                                   onClick={event => {
                                       onAddPerson({id: 0, initials: initials, comment: comment, personalityType: personalityType, organisationType: "Sales"});
                                       setInitials("");
                                       setComment("");
                                       setPersonalityType("");
                                       setShowModal(false);
                                   }}>
                            {t("ADDPERSON.AddPerson")}
                        </IonButton>
                        <IonButton className={style.cancel_button} color={"secondary"} expand={"block"}
                                   onClick={event => {
                                       setInitials("");
                                       setComment("");
                                       setPersonalityType("");
                                       setShowModal(false);
                                   }}>
                            {t("GENERAL.Cancel")}
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonModal>
    )
};

export default AddPersonComponent;
