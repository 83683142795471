import {IonAlert, IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption} from "@ionic/react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {HeaderComponent} from "../../components/Header/Header";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {clearDataStateAction} from "../../store/data/actions";
import {setErrorAction} from "../../store/error/actions";
import {setLanguageAction, setLanguageSetByUser, setShowLoadingAction} from "../../store/system/actions";
import style from './Profile.module.scss';

const ProfileComponent: React.FC = () => {
    const dispatch = useDispatch();
    const currentState = useSelector<AppState, AppState>(state => state);
    const {t, i18n} = useTranslation();
    const [name, setName] = useState(currentState.auth.user.name);
    const [surname, setSurname] = useState(currentState.auth.user.surname);
    const [company, setCompany] = useState(currentState.auth.user.company);
    const [email, setEmail] = useState(currentState.auth.user.email);
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [showPrompt, setShowPromp] = useState(false);
    const history = useHistory();
    const [language, setLanguage] = useState(currentState.system.language);


    const updateUser = async () => {
        dispatch(setShowLoadingAction(true));
        try {
            let user = {...currentState.auth.user};
            user.email = email;
            user.surname = surname;
            user.name = name;
            user.company = company;
            const response = await userApi.updateUser(language, user.id as number, user);
            if (response.data) {
                dispatch(setUserAction(response.data));
                setEmail(response.data.email);
            }
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
        dispatch(setShowLoadingAction(false));
    };


    const changePassword = async () => {
        dispatch(setShowLoadingAction(true));
        try {
            let user = {...currentState.auth.user};
            user.password = password as string;
            await userApi.updatePassword(language, user.id as number, user)

        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
        dispatch(setShowLoadingAction(false));
    };

    const deleteAccount = async () => {
        try {
            await userApi.deleteUser(language, currentState.auth.user.id as number);
            dispatch(clearUserAction() as any);
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const checkChangeUserButtonInvalid = (): boolean => {
        return (name === currentState.auth.user.username && email === currentState.auth.user.email && surname === currentState.auth.user.surname && company === currentState.auth.user.company)
            || email === ""
            || name === ""
            || surname === ""
            || name === undefined
            || surname === undefined
            || !checkEmailValid();
    };

    const checkEmailValid = (): boolean => {
        const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return emailRegEx.test(email as string);
    };


    const setSelectedLanguage = (lang: string): void => {
        setLanguage(lang);
        dispatch(setLanguageAction(lang));
        dispatch(setLanguageSetByUser(true));
    }


    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "secondary"}}/>
            <IonContent className={style.content}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.Name')}*</IonLabel>
                                <IonInput value={name} type="text" onIonChange={event => {
                                    setName(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.Surname')}*</IonLabel>
                                <IonInput value={surname} type="text" onIonChange={event => {
                                    setSurname(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.Company')}</IonLabel>
                                <IonInput value={company} type="text" onIonChange={event => {
                                    setCompany(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.Email')}</IonLabel>
                                <IonInput value={email} type="email" readonly/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton expand={"block"} className={style.change_button} disabled={checkChangeUserButtonInvalid()}
                                       onClick={event => {
                                           updateUser();
                                       }}>
                                <IonLabel>{t('GENERAL.Change')}</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.change_password_row}>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.Password')}</IonLabel>
                                <IonInput type='password' onIonChange={event => {
                                    setPassword(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t('GENERAL.PasswordRepeat')}</IonLabel>
                                <IonInput type="password" onIonChange={event => {
                                    setPasswordRepeat(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {password !== passwordRepeat && <p className={style.invalid}>{t('PROFILE.PasswordsAreDifferent')}</p>}
                            <IonButton expand={"block"} disabled={(password !== passwordRepeat) || password === ''} onClick={event => {
                                changePassword();
                            }}>
                                <IonLabel>{t('GENERAL.Password') + " " + t('GENERAL.Change')}</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("Language.SelectLanguagePlaceholder")}</IonLabel>
                                <IonSelect
                                    value={language}
                                    interface="action-sheet"
                                    onIonChange={e => {
                                        setSelectedLanguage(e.detail.value)
                                    }}>
                                    <IonSelectOption
                                        value="de">
                                        {t("Language.De")}
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value="en">
                                        {t("Language.En")}
                                    </IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.delete_row}>
                        <IonCol>
                            <p>{t("PROFILE.DeleteAccountTitle")}</p>
                            <IonButton expand={"block"} onClick={event => {
                                setShowPromp(true);
                            }}>{t("PROFILE.DeleteAccountButton")}</IonButton>
                            <IonAlert
                                isOpen={showPrompt}
                                header={t("PROFILE.DeleteAccountTitle")}
                                message={t("PROFILE.DeleteAccountMessage")}
                                buttons={[
                                    {
                                        text: t("GENERAL.Cancel"),
                                        role: 'cancel',
                                        handler: () => {
                                            setShowPromp(false);
                                        }
                                    },
                                    {
                                        text: t("GENERAL.Ok"),
                                        handler: () => {
                                            setShowPromp(false);
                                            deleteAccount();
                                        }
                                    }
                                ]}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default ProfileComponent;
