import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {Configuration, DataApi, MiscApi, QuizApi, UserApi} from "./Api";
import App from './App';
import './i18n';
import configureStore, {saveState} from './store';

import * as ReactDOMClient from 'react-dom/client';

export const config = new Configuration();
const env = "test";
const paths = {
    dev: "https://api.mindcodex.de:4040",
    test: "https://api.mindcodex.de:4040"
}
config.basePath = paths[env];
config.baseOptions = ['get', 'post', 'put', 'delete', 'options'];
config.accessToken = "";
export const userApi = new UserApi(config);
export const miscApi = new MiscApi(config);
export const dataApi = new DataApi(config);
export const quizApi = new QuizApi(config);


configureStore().then(persistendStore => {
    const store = persistendStore;

    store.subscribe(() => {
        saveState(store.getState());
    });

    const container = document.getElementById('root');
    const root = ReactDOMClient.createRoot(container as any);

    root.render(
        <Suspense fallback="...loading">
            <Provider store={store}>
                <App />
            </Provider>
        </Suspense>);

});
