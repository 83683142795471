import {Module, Survey, Training} from "../../Api";

export interface DataState {
    lastTraining: Training["id"],
    lastModule: Module,
    sessionKeys: Array<SessionKeyItem>
    storedSessionKeys: Array<SessionKeyItem>
    survey: Survey
    surveyTime: string
}

export const SET_LAST_TRAINING = "SET_LAST_TRAINING";
export const SET_LAST_MODULE = "SET_LAST_MODULE";
export const SET_SESSION_KEYS = "SET_SESSION_KEYS";
export const SET_STORED_SESSION_KEYS = "SET_STORED_SESSION_KEYS";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_SURVEY = "SET_SURVEY";
export const SET_SURVEY_TIME = "SET_SURVEY_TIME";

export interface SetLastTraining {
    type: typeof SET_LAST_TRAINING;
    payload: DataState["lastTraining"];
}

export interface SetSessionKeys {
    type: typeof SET_SESSION_KEYS;
    payload: DataState["sessionKeys"];
}

export interface SetStoredSessionKeys {
    type: typeof SET_STORED_SESSION_KEYS;
    payload: DataState["sessionKeys"];
}

export interface SessionKeyItem {
    key: string,
    version: number
}

export interface SetLastModule {
    type: typeof SET_LAST_MODULE,
    payload: DataState["lastModule"]
}

export interface SetSurvey {
    type: typeof SET_SURVEY,
    payload: DataState["survey"];
}

export interface SetSurveyTime {
    type: typeof SET_SURVEY_TIME,
    payload: DataState["surveyTime"];
}

export interface ClearData {
    type: typeof CLEAR_DATA
}

export type DataActionTypes = SetLastTraining | SetSessionKeys | SetStoredSessionKeys | SetLastModule | ClearData | SetSurvey | SetSurveyTime;


