import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import classNames from "classnames";
import {checkmark} from "ionicons/icons";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setPrivacyAcceptedAction, setShowPrivacyAction} from "../../store/privacy/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Registration.module.scss';

const RegistrationComponent: React.FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const history = useHistory();
    const privacyAccepted = useSelector<AppState, boolean>(state => state.privacy.privacyAccepted);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [company, setCompany] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const language = useSelector<AppState, string>(state => state.system.language);

    const registerNewUser = async () => {
        user.password = password as string;
        user.email = email;
        user.name = name;
        user.surname = surname;
        user.company = company;

        try {
            dispatch(setShowLoadingAction(true));
            await userApi.createUser(language, user);
            setShowAlert(true);
            history.replace("/login");
            dispatch(setShowLoadingAction(false));
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const checkRegisterInputInvalid = (): boolean => {
        return password === ""
            || email === ""
            || name === ""
            || surname === ""
            || !checkEmailValid()
            || !checkPasswordValid();
    };

    const checkEmailValid = (): boolean => {
        const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return emailRegEx.test(email as string);
    };

    const checkPasswordValid = (check?: number): boolean => {
        let passwordRegEx;

        switch (check) {
            case 0:
                passwordRegEx = /.{8,}/;
                break;
            case 1:
                passwordRegEx = /.*\d/;
                break;
            case 2:
                passwordRegEx = /.*[A-Z]/;
                break;
            case 3:
                passwordRegEx = /.*[!"§$%&/()=?+-.:;,_'#*<>€@]/;
                break;
            default:
                passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"§$%&/()=?+-.:;,_'#*<>€@]).{8,}$/;

        }

        return passwordRegEx.test(password as string);
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={"module"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/home" text={""}/>
                    </IonButtons>
                    <IonTitle>{t('REGISTRATION.Title')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color={"module"}>
                <IonAlert
                    isOpen={showAlert}
                    header={t("REGISTRATION.SuccessAlertHeader")}
                    message={t("REGISTRATION.SuccessAlertMessage")}
                    buttons={[t('GENERAL.Ok')]}
                />

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("GENERAL.Name")}*</IonLabel>
                                <IonInput type="text" value={name} onIonChange={event => {
                                    setName(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("GENERAL.Surname")}*</IonLabel>
                                <IonInput type="text" value={surname} onIonChange={event => {
                                    setSurname(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("GENERAL.Company")}</IonLabel>
                                <IonInput type="text" value={company} onIonChange={event => {
                                    setCompany(event.detail.value as string);
                                }}/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("GENERAL.Email")}*</IonLabel>
                                <IonInput type="email" value={email} onIonChange={event => {
                                    setEmail(event.detail.value as string);
                                }}/>
                            </IonItem>
                            {!checkEmailValid() && email !== "" && <p className={style.invalid_password}>{t("REGISTRATION.InvalidEmail")}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel position="floating">{t("GENERAL.Password")}*</IonLabel>
                                <IonInput type="password" value={password} onIonChange={event => {
                                    setPassword(event.detail.value as string);
                                }}/>
                            </IonItem>
                            <p className={classNames({[style.invalid_password]: !checkPasswordValid(0)}, {[style.valid_password]: checkPasswordValid(0)})}>{t("REGISTRATION.InvalidPassword1")}
                                {checkPasswordValid(0) && <IonIcon className={style.checkmark} icon={checkmark}/>}
                            </p>
                            <p className={classNames({[style.invalid_password]: !checkPasswordValid(1)}, {[style.valid_password]: checkPasswordValid(1)})}>{t("REGISTRATION.InvalidPassword2")}
                                {checkPasswordValid(1) && <IonIcon className={style.checkmark} icon={checkmark}/>}
                            </p>
                            <p className={classNames({[style.invalid_password]: !checkPasswordValid(2)}, {[style.valid_password]: checkPasswordValid(2)})}>{t("REGISTRATION.InvalidPassword3")}
                                {checkPasswordValid(2) && <IonIcon className={style.checkmark} icon={checkmark}/>}
                            </p>
                            <p className={classNames({[style.invalid_password]: !checkPasswordValid(3)}, {[style.valid_password]: checkPasswordValid(3)})}>{t("REGISTRATION.InvalidPassword4")}
                                {checkPasswordValid(3) && <IonIcon className={style.checkmark} icon={checkmark}/>}
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={style.item}>
                                <IonLabel><p className={style.privacy}><span className={style.privacy_link} onClick={event => {
                                    event.preventDefault();
                                    dispatch(setShowPrivacyAction(true));
                                }}>{t("REGISTRATION.AcceptPrivacy")}</span> {t("REGISTRATION.AcceptPrivacy2")}</p></IonLabel>
                                <IonToggle checked={privacyAccepted} onIonChange={e => {
                                    if (e.detail.checked) {
                                        dispatch(setPrivacyAcceptedAction(true));
                                    } else {
                                        dispatch(setPrivacyAcceptedAction(false));
                                    }
                                }} slot="end"/>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" className={style.register_button} disabled={!privacyAccepted || checkRegisterInputInvalid()} onClick={event => {
                                registerNewUser()
                            }}>
                                {t("REGISTRATION.SubmitButton")}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default RegistrationComponent;
