import {App} from "@capacitor/app";

import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, useIonViewDidEnter} from '@ionic/react';
import classNames from "classnames";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import ThreeComponent from "../../components/Three/Three";
import {userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from './Home.module.scss';
import { useIonRouter } from "@ionic/react";

const HomeComponent: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector<AppState, User>(state => state.auth.user);
    const personalityName = useSelector<AppState, string>(state => state.auth.user.personalityName as string);
    const [hasDiamond, setHasDiamond] = useState<boolean>(true);
    const history = useHistory();
    const language = useSelector<AppState, string>(state => state.system.language);
    const isAuth = useSelector<AppState, boolean>(state => state.auth.isAuthenticated);


    useIonViewDidEnter(() => {
        console.log("[HOME] useIonViewDidEnter (enter)");
        const reloadUser = async () => {
            try {
                console.log("[HOME] useIonViewDidEnter / reloadUser (enter)");
                dispatch(setShowLoadingAction(true));
                const response = await userApi.getUser(user.id as number, language);
                dispatch(setUserAction(response.data));
                
                console.log(response.data);
                console.log(user);

                if (response.data.typeRed && response.data.typeGreen && response.data.typeBlue) {
                    setHasDiamond(true);
                } else {
                    setHasDiamond(false);
                }
                if (!response.data.showResult) {
                    setHasDiamond(false);
                }
                dispatch(setShowLoadingAction(false));
                console.log("[HOME] useIonViewDidEnter / reloadUser (leave)");
            } catch (e) {
                console.log("[HOME] useIonViewDidEnter / reloadUser (exception enter)");
                setHasDiamond(false);
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
                console.log("[HOME] useIonViewDidEnter / reloadUser (exception leave)");
            }
        };

        if (isAuth) {
            reloadUser();
        }
        
    }, [language, user])

    const noDiamondContent = () => {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol className={style.diamond_col}>
                        <img className={style.diamond_logo} src={"./assets/diamond/empty_diamond.svg"} alt={""}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={style.no_data_text}>
                        <p>{t('HOME.NoDiamondData')}</p>
                        <p>{t('HOME.NoDiamondData2')}</p>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    };


    const diamondContent = () => {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol className={style.diamond_col}>
                        <ThreeComponent id={"home"} red={user.typeRed} blue={user.typeBlue} green={user.typeGreen}
                                        bottomRed={user.subTypeRed} bottomBlue={user.subTypeBlue} bottomGreen={user.subTypeGreen}/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={style.type}>
                        <p>{personalityName}</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton className={style.round_button} expand="block" fill="outline" color="white" routerLink='/home/big5' routerDirection="forward">{t('HOME.Big5')}</IonButton>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton className={style.round_button} expand="block" fill="outline" color="white" routerLink='/home/dominances'
                                   routerDirection="forward">{t('HOME.Evaluation')}</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    };

    const ionRouter = useIonRouter();
    document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
        App.exitApp();
        }
    });
    });

    return (
        <IonPage>
            {!hasDiamond && <HeaderComponent {...{color: "headerBlue"}}/>}
            {hasDiamond && <HeaderComponent {...{color: "dark"}}/>}
            <IonContent className={classNames({[style.content]: !hasDiamond}, {[style.contentWithDiamond]: hasDiamond})}>
                {hasDiamond ? diamondContent() : noDiamondContent()}
            </IonContent>
        </IonPage>
    );
};

export default HomeComponent;
