import {IonAlert, IonCol, IonContent, IonGrid, IonPage, IonRow} from "@ionic/react";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {Session} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {deleteStoredSessionAction} from "../../store/data/actions";
import {DataState} from "../../store/data/types";
import {setErrorAction} from "../../store/error/actions";
import style from "./Session.module.scss";
import { Preferences } from '@capacitor/preferences';
import {Filesystem} from "@capacitor/filesystem";


const SessionComponent: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const {sessionId} = useParams<{ sessionId: string }>();
    const [session, setSession] = useState<Session>({});
    const [image, setImage] = useState<string>("");
    const [showAlert, setShowAlert] = useState(false);
    const storedSessionKeys = useSelector<AppState, DataState["sessionKeys"]>(state => state.data.storedSessionKeys);

    useEffect(() => {
        const getImage = async (loadedSession: Session) => {
            Filesystem.readFile({
                path: loadedSession.imageFile as string
            }).then(res => {
                const loadedImage = "data:image/png;base64," + res.data;
                setImage(loadedImage);
            }).catch(err => {
                console.error(err);
                dispatch(deleteStoredSessionAction(sessionId, storedSessionKeys))
                setShowAlert(true);
            });
        };

        const setSessionRead = async (loadedSession: Session) => {
            try {
                await dataApi.setSessionAsRead(loadedSession.id as number);
            } catch (e) {
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
            }
        };

        Preferences.get({key: "Session-" + sessionId}).then(async data => {
            if (data.value) {
                const loadedSession = JSON.parse(data.value);
                console.log(loadedSession);
                setSession(loadedSession);
                await getImage(loadedSession);
                await setSessionRead(loadedSession);
            } else {
                setTimeout(() => {
                    setShowAlert(true);
                }, 1500);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);


    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "fixedHeaderBlue"}}/>
            <IonContent>
                <IonAlert
                    isOpen={showAlert}
                    header={t("ERROR.ErrorTitle")}
                    message={t('SESSION.SessionNotLoaded')}
                    buttons={[
                        {
                            text: t('GENERAL.Ok'),
                            handler: () => {
                                dispatch(deleteStoredSessionAction(sessionId as string, storedSessionKeys));
                                history.goBack();
                            }
                        }
                    ]}
                />
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {image && <img src={image} alt={""}/>}
                            {session.videoUrl &&
                            <video controls preload="metadata" autoPlay={false} webkit-playsinline="webkit-playsinline" className={style.video_player}>
                                <source src={session.videoUrl + "#t=0.1"} type="video/mp4"/>
                            </video>}
                            <p dangerouslySetInnerHTML={{__html: session.introText ? session.introText : ''}}/>

                            {session.audioUrl &&
                            <audio controls>
                                <source src={session.audioUrl} type="audio/mpeg"/>
                                {t("SESSION.NoAudio")}
                            </audio>
                            }

                            <p dangerouslySetInnerHTML={{__html: session.endText ? session.endText : ''}}/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );

};

export default SessionComponent;
