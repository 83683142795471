import { Preferences } from '@capacitor/preferences';
import {Filesystem, Directory} from "@capacitor/filesystem";
import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonSpinner} from "@ionic/react";
import Axios from "axios";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {Module, Session, Training} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setStoredSessionKeysAction} from "../../store/data/actions";
import {setErrorAction} from "../../store/error/actions";
import style from './Training.module.scss';


const TrainingComponent: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const module = useSelector<AppState, Module>(state => state.data.lastModule);
    const trainingId = useSelector<AppState, Training["id"]>(state => state.data.lastTraining);
    const storedSessionKeys = useSelector<AppState, any>(state => state.data.storedSessionKeys);
    const language = useSelector<AppState, string>(state => state.system.language);
    const [showDownload, setShowDownload] = useState<Array<boolean>>([]);
    const [showSpinner, setShowSpinner] = useState<Array<boolean>>([]);
    const [training, setTraining] = useState<Training>({});

    // show download button if session isn't in storage or has a different version number
    useEffect(() => {
        const checkDownloadButtons = (trainingMatch: Training) => {
            const newShowDownloads = [];
            const newShowSpinner = [];
            if (trainingMatch.sessions) {
                for (let session of trainingMatch.sessions) {
                    let bool = true;
                    for (let storedKey of storedSessionKeys) {
                        if (storedKey.key === "Session-" + session.id) {
                            bool = storedKey.version !== session.version;
                        }
                    }
                    newShowDownloads.push(bool);
                    newShowSpinner.push(false);
                }
                setShowDownload(newShowDownloads);
                setShowSpinner(newShowSpinner);
            }
        };

        const getTraining = () => {
            if (module.training) {
                for (let item of module.training) {
                    if (item.id === trainingId) {
                        setTraining((item));
                        checkDownloadButtons(item);
                        break;
                    }
                }
            }
        };
        getTraining();
    }, [module.training, trainingId, storedSessionKeys]);


    const downloadImage = async (sessionId: number, url: string): Promise<any> => {
        try {
            const result = await Axios.get(url, {responseType: "arraybuffer"});
            const image = new Buffer(result.data, "base64").toString("base64");
            const filePath = "Session-" + sessionId + "-image.jpg";
            await Filesystem.writeFile({
                data: image,
                path: filePath,
                directory: Directory.Data
            });
            const file = await Filesystem.getUri({
                directory: Directory.Data,
                path: filePath
            });
            return file.uri;
        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    };


    const downloadSession = async (session: Session, i: number) => {
        console.log("Downloading Session");
        try {
            // show loading spinner while downloading
            let newShowSpinner = [...showSpinner];
            newShowSpinner[i] = true;
            setShowSpinner(newShowSpinner);

            // get session
            const response = await dataApi.getSession(session.id as number, language);

            // load assets
            response.data.imageFile = await downloadImage(session.id as number, response.data.imageUrl as string);

            const newKey = {
                key: "Session-" + response.data.id,
                version: response.data.version
            };

            // override old sessionKey if it already exists (in case of update)
            let isUpdate = false;
            for (let i = 0; i < storedSessionKeys.length; i++) {
                if (storedSessionKeys[i].key === newKey.key) {
                    isUpdate = true;
                    storedSessionKeys[i].version = newKey.version;
                }
            }

            if (!isUpdate) {
                storedSessionKeys.push(newKey);
            }

            // store session
            dispatch(setStoredSessionKeysAction(storedSessionKeys));
            Preferences.set({key: "Session-" + response.data.id, value: JSON.stringify(response.data)});

            // hide download button and spinner
            const newShowDownload = [...showDownload];
            newShowDownload[i] = false;
            setShowDownload(newShowDownload);
            newShowSpinner = [...showSpinner];
            newShowSpinner[i] = false;
            setShowSpinner(newShowSpinner);


        } catch (e) {
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            const newShowSpinner = [...showSpinner];
            newShowSpinner[i] = false;
            setShowSpinner(newShowSpinner);
            dispatch(setErrorAction(e) as any);
        }
    };


    const canOpenSession = (session: Session, i: number) => {
        let bool = false;
        let checkKey = {
            key: "Session-" + session.id,
            version: session.version
        };

        for (let storedKey of storedSessionKeys) {
            if (storedKey.key === checkKey.key) {
                bool = true;
            }
        }
        return bool;
    };


    const sessionList = () => {
        return (
            <div>
                {training.sessions?.map((session, i) => {
                    return (
                        <IonRow key={i}>
                            <IonCol className={style.session}>

                                {/*/!*download button*!/*/}
                                {/*{showDownload[i] && !showSpinner[i] && !session.isBlank &&*/}
                                {/*<IonButton className={style.download_button} onClick={event => {*/}
                                {/*    downloadSession(session, i);*/}
                                {/*}}>{t("TRAININGS.Download")}</IonButton>}*/}

                                {/*/!*read more button*!/*/}
                                {/*{!showDownload[i] && !showSpinner[i] && !session.isBlank &&*/}
                                {/*<IonButton className={style.download_button} onClick={event => {*/}
                                {/*    if (canOpenSession(session, i)) {*/}
                                {/*        history.push('/modules/session/' + session.id)*/}
                                {/*    }*/}
                                {/*}*/}
                                {/*}>{t("TRAININGS.ReadMore")}</IonButton>}*/}


                                {showSpinner[i] &&
                                <IonSpinner name={"crescent"}/>}

                                <p className={style.session_title}>{session.title} </p>
                                {session.introText && <p dangerouslySetInnerHTML={{__html: session.introText}}/>}

                                {session && session.contentType && [5,6].includes(session.contentType) &&
                                    <IonButton expand={"block"} className={style.module_button} routerLink={`/quiz/${session.contentType}/${session.id}/normal`}>{t("QUIZ.StartNormal")}</IonButton>
                                }
                                
                                {session && session.contentType && [5,6].includes(session.contentType) &&
                                <IonButton expand={"block"} className={style.module_button} routerLink={`/quiz/${session.contentType}/${session.id}/fast`}>{t("QUIZ.StartFast")}</IonButton>
                                }

                            </IonCol>
                        </IonRow>
                    );
                })}
            </div>
        )
    };

    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "fixedHeaderBlue", defaultHref: "/modules"}}/>
            <IonContent fullscreen className={style.content}>
                <IonGrid className={style.grid}>
                    <IonRow className={style.title_row}>
                        <IonCol>
                            <p className={style.title}>{t("TRAININGS.Title")} {training.title}</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className={style.image_row}>
                            {module.iconFile && <img src={module.iconFile} alt={""}/>}
                        </IonCol>
                    </IonRow>
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}
                    {/*        <p>{training.description}</p>*/}
                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    <IonRow>
                        <IonCol>
                            {sessionList()}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
};

export default TrainingComponent
