import {CLEAR_DATA, DataActionTypes, DataState, SET_LAST_MODULE, SET_LAST_TRAINING, SET_SESSION_KEYS, SET_STORED_SESSION_KEYS, SET_SURVEY, SET_SURVEY_TIME} from "./types";

const INITIAL_STATE: DataState = {
    lastTraining: 0,
    lastModule: {},
    sessionKeys: [],
    storedSessionKeys: [],
    survey: {questions: []},
    surveyTime: ""
};

export const dataReducer = (state: DataState = INITIAL_STATE, action: DataActionTypes) => {
    switch (action.type) {
        case SET_LAST_TRAINING:
            return {...state, lastTraining: action.payload};
        case SET_SESSION_KEYS:
            return {...state, sessionKeys: action.payload};
        case SET_STORED_SESSION_KEYS:
            return {...state, storedSessionKeys: action.payload};
        case SET_LAST_MODULE:
            return {...state, lastModule: action.payload};
        case SET_SURVEY:
            return {...state, survey: action.payload}
        case SET_SURVEY_TIME:
            return {...state, surveyTime: action.payload}
        case CLEAR_DATA:
            return INITIAL_STATE;
        default:
            return {...state};
    }
};
