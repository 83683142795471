import {CHANGE_LANGUAGE, CLEAR_SYSTEM, SET_BUILD, SET_LANGUAGESETBYUSER, SET_SHOW_LOADING, SET_VERSION, SystemActionTypes, SystemState} from "./types";

const INITIAL_STATE: SystemState = {
    language: "en",
    showLoading: false,
    languageSetByUser: false,
    version: "",
    build: ""
};

export const systemReducer = (state: SystemState = INITIAL_STATE, action: SystemActionTypes): SystemState => {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {...state, language: action.payload}
        }
        case SET_SHOW_LOADING: {
            return {...state, showLoading: action.payload}
        }
        case CLEAR_SYSTEM: {
            return INITIAL_STATE;
        }
        case SET_LANGUAGESETBYUSER: {
            return {...state, languageSetByUser: action.payload}
        }
        case SET_VERSION: {
            return {...state, version: action.payload}
        }
        case SET_BUILD: {
            return {...state, build: action.payload}
        }
        default:
            return state;
    }
};

