import {IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/react';
import classNames from "classnames";
import React from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {HeaderComponent} from "../../components/Header/Header";
import {AppState} from "../../store";
import {clearUserAction} from "../../store/auth/actions";
import {setShowPrivacyAction} from "../../store/privacy/actions";
import style from './Info.module.scss';

const InfoComponent: React.FC = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const currentState = useSelector<AppState, AppState>(state => state);
    const history = useHistory();
    const version = useSelector<AppState, string>(state => state.system.version);
    const build = useSelector<AppState, string>(state => state.system.build);

    return (
        <IonPage>
            <HeaderComponent {...{showButton: true, color: "headerBlue"}}/>
            <IonContent className={style.content}>
                <IonGrid className={style.grid}>
                    <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" href="https://www.mindcodex.de/ueber-uns" target="_blank">{t('INFO.Contact')}</IonButton>
                        </IonCol>
                    </IonRow>

                    <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" href="https://www.mindcodex.de/trainings-coachings" target="_blank">{t('INFO.SeminarBooking')}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" href="https://www.mindcodex.de/trainings-coachings" target="_blank">{t('INFO.CoachingBooking')}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" href="https://www.mindcodex.de/trainings-coachings" target="_blank">{t('INFO.ImpulsRequest')}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow className={classNames(style.button_row, style.extern_buttons)}>
                        <IonCol>
                            <IonButton expand="block" href="https://www.mindcodex.de/trainings-coachings" target="_blank">{t('INFO.BecomeTrainer')}</IonButton>
                        </IonCol>
                    </IonRow>

                    <IonRow className={classNames(style.button_row)}>
                        <IonCol>
                            <IonButton expand="block" color={"white"} fill={"outline"} onClick={event => {
                                dispatch(setShowPrivacyAction(true));
                            }}>{t('INFO.Privacy')}</IonButton>
                        </IonCol>
                    </IonRow>

                    {currentState.auth.isAuthenticated && <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" color={"white"} fill={"outline"} routerLink='/info/profile' routerDirection='forward'>{t('GENERAL.Profile')}</IonButton>
                        </IonCol>
                    </IonRow>}
                    {currentState.auth.isAuthenticated && <IonRow className={style.button_row}>
                        <IonCol>
                            <IonButton expand="block" color={"white"} fill={"outline"} onClick={event => {
                                dispatch(clearUserAction() as any);
                                history.replace('/login');
                            }}>{t('GENERAL.Logout')}</IonButton>
                        </IonCol>
                    </IonRow>}
                </IonGrid>
                {version && build && <p className={style.version}>{version} ({build})</p>}
            </IonContent>
        </IonPage>
    );
};

export default InfoComponent;
