import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonGrid, IonItem, IonLabel, IonList, IonPage, IonProgressBar, IonRadio, IonRadioGroup, IonRow} from "@ionic/react";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {Survey, User} from "../../Api";
import {HeaderComponent} from "../../components/Header/Header";
import {dataApi, userApi} from "../../index";
import {AppState} from "../../store";
import {clearUserAction, setUserAction} from "../../store/auth/actions";
import {setSurveyAction, setSurveyTimeAction} from "../../store/data/actions";
import {setErrorAction} from "../../store/error/actions";
import {setShowLoadingAction} from "../../store/system/actions";
import style from "../Survey/Survey.module.scss";

const SurveyComponent: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector<AppState, string>(state => state.system.language);
    const user = useSelector<AppState, User>(state => state.auth.user);
    const storedSurvey = useSelector<AppState, Survey>(state => state.data.survey);
    const [survey, setSurvey] = useState<Survey>({questions: []});
    const [showQuestions, setShowQuestions] = useState<Array<boolean>>([]);
    const [progress, setProgress] = useState<number>(0);
    const [backCount, setBackCount] = useState<number>(0);
    const {t} = useTranslation();
    const surveyTime = useSelector<AppState, string>(state => state.data.surveyTime);
    const [showIntro, setShowIntro] = useState<boolean>(true);

    useEffect(() => {
        const getSurvey = async () => {
            try {
                const response = await dataApi.getSurvey(language);
                setSurvey(response.data);

                let slideWithoutAnswer = 0;

                if (response.data && response.data.questions) {

                    // stored survey
                    if (moment().diff(moment(surveyTime), "hours") < 4) {
                        if (storedSurvey && storedSurvey.questions) {
                            slideWithoutAnswer = storedSurvey.questions.length !== 0 ? storedSurvey.questions.length - 1 : 0;
                            // delete if length of questions is not equal
                            if (storedSurvey.questions.length != response.data.questions.length) {
                                dispatch(setSurveyAction(response.data));
                            } else {
                                for (let i = 0; i < storedSurvey.questions?.length; i++) {
                                    // look for the first question that has no answer
                                    if (storedSurvey.questions[i].selectedAnswer == -1) {
                                        slideWithoutAnswer = i;
                                        break;
                                    }
                                    // restore answers
                                    response.data.questions[i].selectedAnswer = storedSurvey.questions[i].selectedAnswer;
                                }
                            }
                        }
                    }


                    // setup handler array to show each question
                    if (response.data.questions) {
                        const temp = [];
                        for (let question of response.data.questions) {
                            temp.push(false);
                        }

                        // restore last question
                        temp[slideWithoutAnswer] = true
                        setShowQuestions(temp)
                    }

                }

            } catch (e) {
                if ((e as any).response?.status === 401) {
                    dispatch(clearUserAction() as any);
                    history.replace("/login");
                }
                dispatch(setErrorAction(e) as any);
            }
        }
        getSurvey();
    }, [language])


    const setSelectedAnswer = (i: number, value: number) => {
        setTimeout(() => {

            if (survey.questions) {
                survey.questions[i].selectedAnswer = value;
                calcProgress();
                dispatch(setSurveyAction(survey));
                // show next question
                const temp = showQuestions.map(e => false);
                if (temp.length - 1 !== i) {
                    temp[i + 1] = true
                    setShowQuestions(temp);
                }
                // count question
                setBackCount(i + 1);
            }
            if (i === 0) {
                //set start date of survey
                dispatch(setSurveyTimeAction(moment().toISOString()));
            }
        }, 500)
    }


    useEffect(() => {
        calcProgress()
    }, [survey])

    const calcProgress = () => {
        if (survey.questions) {
            let answered = 0;
            for (let j = 0; j < survey.questions.length; j++) {
                if (survey.questions[j].selectedAnswer != -1) {
                    answered++;
                }
            }
            let progress = (100 / survey.questions.length * answered) / 100;
            setProgress(progress);
        }
    }

    const completeSurvey = async () => {
        dispatch(setShowLoadingAction(true));
        try {
            await dataApi.saveSurvey(user.id as number, language, survey);
            const response = await userApi.getUser(user.id as number, language);
            dispatch(setUserAction(response.data));

            //reset survey
            dispatch(setSurveyAction({questions: []}));
            const temp = showQuestions.map(e => false);
            temp[0] = true;
            setShowQuestions(temp);
            history.replace("/home");
            dispatch(setShowLoadingAction(false));
        } catch (e) {
            dispatch(setShowLoadingAction(false));
            if ((e as any).response?.status === 401) {
                dispatch(clearUserAction() as any);
                history.replace("/login");
            }
            dispatch(setErrorAction(e) as any);
        }
    }

    const nl2br = (text: string) => {
        return text.replace(/(\r\n|\n\r|\r|\n)/gm, "<br>");
    }


    return (
        <IonPage>
            <HeaderComponent {...{color: "headerRed"}}/>
            <IonContent>
                {showIntro && <IonGrid className={style.grid}>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <IonCardContent className={style.cardContent}>
                                    <IonRow>
                                        <IonCol>
                                            <p dangerouslySetInnerHTML={{__html: nl2br(t("SURVEY.Intro"))}}/>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className={style.startSurveyButtonCol}>
                                            <IonButton className={style.startSurveyButton} onClick={() => {
                                                setShowIntro(false);
                                            }
                                            }>{t("SURVEY.Start")}</IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>}
                {!showIntro && <IonGrid className={style.gridSurvey}>
                    <IonRow className={style.cardDimension}>
                        <IonCol>
                            {!showIntro && survey?.questions?.map((item, i) => {
                                if (showQuestions[i]) {
                                    return (
                                        <IonCard key={i}>
                                            <IonCardHeader>
                                                <h4>{t("SURVEY.QuestionHeader") + " " + (i + 1)}</h4>
                                            </IonCardHeader>
                                            <IonCardContent className={style.cardContent}>
                                                <p>{item.question}</p>
                                                <br/>
                                                <IonList className={style.surveyList}>
                                                    <IonRadioGroup value={survey.questions ? survey.questions[i].selectedAnswer : undefined} onIonChange={(e) => {
                                                        setSelectedAnswer(i, e.detail.value as number)
                                                    }}>
                                                        {item.answers?.map((answer, j) => {
                                                            return (
                                                                <IonRow key={j}>
                                                                    <IonCol className={style.answer}>
                                                                        <IonItem>
                                                                            <IonLabel className={style.label}>{answer.text}</IonLabel>
                                                                            <IonRadio mode={"md"} value={answer.id}/>
                                                                        </IonItem>
                                                                    </IonCol>
                                                                </IonRow>
                                                            )
                                                        })}
                                                    </IonRadioGroup>
                                                </IonList>
                                                <IonRow>
                                                    <IonCol className={style.leftButton}>
                                                        {(i !== 0) && (backCount == i || progress === 1) && <IonButton onClick={() => {
                                                            const temp = showQuestions.map(e => false);
                                                            temp[i - 1] = true
                                                            setShowQuestions(temp);
                                                        }}>{t("GENERAL.Back")}</IonButton>}
                                                    </IonCol>

                                                    <IonCol className={style.rightButton}>
                                                        {(backCount - 1 == i) && survey.questions && (i != survey.questions.length - 1) && <IonButton onClick={() => {
                                                            const temp = showQuestions.map(e => false);
                                                            temp[i + 1] = true
                                                            setShowQuestions(temp);
                                                        }}>{t("GENERAL.Next")}</IonButton>}

                                                        {survey.questions && (i == survey.questions.length - 1) && progress === 1 && <IonButton onClick={() => {
                                                            completeSurvey()
                                                        }
                                                        }>{t("GENERAL.Complete")}</IonButton>}
                                                    </IonCol>
                                                </IonRow>

                                            </IonCardContent>
                                        </IonCard>
                                    )
                                } else {
                                    return (<div key={i}/>)
                                }
                            })}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonProgressBar value={progress}/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                }
            </IonContent>
        </IonPage>
    )
}

export default SurveyComponent;
